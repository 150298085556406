import {Dispatch} from "redux";

import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";

export const setOfferDetailViewTypes = createRequestActionTypes({name: "setViewType", type: "POST", view: "OfferList"});

export const setViewModeForOfferDetail = (_1: Partial<IServices>) => async (dispatch: Dispatch) => {
    dispatch({type: setOfferDetailViewTypes.success, viewType: "offerList"});
    return true;
};

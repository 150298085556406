import {Dispatch} from "redux";

import {IAction} from "../../app/interfaces/IAction";
import {ViewType} from "./view_type";

export interface ISetViewTypeAction extends IAction {
    current: ViewType;
}

export const SET_VIEW_TYPE = "SET_VIEW_TYPE";
const setViewType = (viewType: ViewType) => async (dispatch: Dispatch) => {
    dispatch({type: SET_VIEW_TYPE, current: viewType});
    return true;
};

export const setHomepageViewTypeAtRoute = () => setViewType(ViewType.HOMEPAGE);

export enum CarMakeTypes {
    AUDI = "Audi",
    BMW = "BMW",
    FORD = "Ford",
    MERCEDESBENZ = "Mercedes-Benz",
    OPEL = "Opel",
    VOLKSWAGEN = "Volkswagen",
    RENAULT = "Renault",
    ABARTH = "Abarth",
    ACURA = "Acura",
    AIXAM = "Aixam",
    ALFAROMEO = "Alfa Romeo",
    ALPINA = "Alpina",
    ALPINE = "Alpine",
    ASTONMARTIN = "Aston Martin",
    AURUS = "Aurus",
    AUSTIN = "Austin",
    AUSTINHEALEY = "Austin-Healey",
    BAIC = "BAIC",
    BEDFORD = "Bedford",
    BELLIER = "Bellier",
    BENTLEY = "Bentley",
    BOLDMEN = "Boldmen",
    BRILLIANCE = "Brilliance",
    BUGATTI = "Bugatti",
    BUICK = "Buick",
    BYD = "BYD",
    CADILLAC = "Cadillac",
    CARAVANSWOHNM = "Caravans-Wohnm",
    CARVER = "Carver",
    CHATENET = "Chatenet",
    CHERY = "Chery",
    CHEVROLET = "Chevrolet",
    CHRYSLER = "Chrysler",
    CIRELLI = "Cirelli",
    CITROEN = "Citroen",
    CORVETTE = "Corvette",
    CUPRA = "CUPRA",
    DACIA = "Dacia",
    DAEWOO = "Daewoo",
    DAF = "DAF",
    DAIHATSU = "Daihatsu",
    DODGE = "Dodge",
    DONKERVOORT = "Donkervoort",
    DUTTON = "Dutton",
    EVO = "EVO",
    FERRARI = "Ferrari",
    FIAT = "Fiat",
    GEMBALLA = "GEMBALLA",
    GENESIS = "Genesis",
    GMC = "GMC",
    GREATWALL = "Great Wall",
    GTA = "GTA",
    GWM = "GWM",
    HONDA = "Honda",
    HUMMER = "HUMMER",
    HYUNDAI = "Hyundai",
    INFINITI = "Infiniti",
    INNOCENTI = "Innocenti",
    ISUZU = "Isuzu",
    IVECO = "Iveco",
    IZH = "IZH",
    JAC = "JAC",
    JAGUAR = "Jaguar",
    JEEP = "Jeep",
    KIA = "Kia",
    KTM = "KTM",
    LADA = "Lada",
    LAMBORGHINI = "Lamborghini",
    LANCIA = "Lancia",
    LANDROVER = "Land Rover",
    LDV = "LDV",
    LEVC = "LEVC",
    LEXUS = "Lexus",
    LINCOLN = "Lincoln",
    LOTUS = "Lotus",
    MAN = "MAN",
    MANSORY = "Mansory",
    MARTIN = "Martin",
    MARTINMOTORS = "Martin Motors",
    MASERATI = "Maserati",
    MATRA = "Matra",
    MAXUS = "Maxus",
    MAYBACH = "Maybach",
    MAZDA = "Mazda",
    MCLAREN = "McLaren",
    MEGA = "Mega",
    MELEX = "Melex",
    MERCURY = "Mercury",
    MG = "MG",
    MICRO = "Micro",
    MICROCAR = "Microcar",
    MILITEM = "Militem",
    MINARI = "Minari",
    MINAUTO = "Minauto",
    MINI = "MINI",
    MITSUBISHI = "Mitsubishi",
    MITSUOKA = "Mitsuoka",
    MORGAN = "Morgan",
    MOSKVICH = "Moskvich",
    MPLAFER = "MP Lafer",
    MPMMOTORS = "MPM Motors",
    NIO = "NIO",
    NISSAN = "Nissan",
    NSU = "NSU",
    OMODA = "Omoda",
    ORA = "Ora",
    PAGANI = "Pagani",
    PEUGEOT = "Peugeot",
    PGO = "PGO",
    PIAGGIO = "Piaggio",
    PLYMOUTH = "Plymouth",
    POLESTAR = "Polestar",
    PONTIAC = "Pontiac",
    PORSCHE = "Porsche",
    PROTON = "Proton",
    PUCH = "Puch",
    RAM = "RAM",
    REGIS = "Regis",
    ROLLSROYCE = "Rolls-Royce",
    ROVER = "Rover",
    SAAB = "Saab",
    SEAT = "SEAT",
    SKODA = "Skoda",
    SMART = "smart",
    SSANGYONG = "SsangYong",
    STREETSCOTER = "StreetScooter",
    SUBARU = "Subaru",
    SUZUKI = "Suzuki",
    TATA = "Tata",
    TESLA = "Tesla",
    TOYOTA = "Toyota",
    TRABANT = "Trabant",
    TRIUMPH = "Triumph",
    UAZ = "UAZ",
    VOLVO = "Volvo",
    INNE = "Inne"
}

//export const carMakes: string[] = Object.values(CarMakeTypes);

export const carMakes: string[] = [
    "Audi",
    "BMW",
    "Ford",
    "Mercedes-Benz",
    "Opel",
    "Volkswagen",
    "Renault",
    "Abarth",
    "Acura",
    "Aixam",
    "Alfa Romeo",
    "Alpina",
    "Alpine",
    "Aston Martin",
    "Aurus",
    "Austin",
    "Austin-Healey",
    "BAIC",
    "Bedford",
    "Bellier",
    "Bentley",
    "Boldmen",
    "Brilliance",
    "Bugatti",
    "Buick",
    "BYD",
    "Cadillac",
    "Caravans-Wohnm",
    "Carver",
    "Chatenet",
    "Chery",
    "Chevrolet",
    "Chrysler",
    "Cirelli",
    "Citroen",
    "Corvette",
    "CUPRA",
    "Dacia",
    "Daewoo",
    "DAF",
    "Daihatsu",
    "Dodge",
    "Donkervoort",
    "Dutton",
    "EVO",
    "Ferrari",
    "Fiat",
    "GEMBALLA",
    "Genesis",
    "GMC",
    "Great Wall",
    "GTA",
    "GWM",
    "Honda",
    "HUMMER",
    "Hyundai",
    "Infiniti",
    "Innocenti",
    "Isuzu",
    "Iveco",
    "IZH",
    "JAC",
    "Jaguar",
    "Jeep",
    "Kia",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "LDV",
    "LEVC",
    "Lexus",
    "Lincoln",
    "Lotus",
    "MAN",
    "Mansory",
    "Martin",
    "Martin Motors",
    "Maserati",
    "Matra",
    "Maxus",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mega",
    "Melex",
    "Mercury",
    "MG",
    "Micro",
    "Microcar",
    "Militem",
    "Minari",
    "Minauto",
    "MINI",
    "Mitsubishi",
    "Mitsuoka",
    "Morgan",
    "Moskvich",
    "MP Lafer",
    "MPM Motors",
    "NIO",
    "Nissan",
    "NSU",
    "Omoda",
    "Ora",
    "Pagani",
    "Peugeot",
    "PGO",
    "Piaggio",
    "Plymouth",
    "Polestar",
    "Pontiac",
    "Porsche",
    "Proton",
    "Puch",
    "RAM",
    "Regis",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "SEAT",
    "Skoda",
    "smart",
    "SsangYong",
    "StreetScooter",
    "Subaru",
    "Suzuki",
    "Tata",
    "Tesla",
    "Toyota",
    "Trabant",
    "Triumph",
    "UAZ",
    "Volvo",
    "Inne"
];

export const UMakeTypes = {
    audi: "Audi",
    bmw: "BMW",
    ford: "Ford",
    mercedesbenz: "Mercedes-Benz",
    opel: "Opel",
    volkswagen: "Volkswagen",
    renault: "Renault",
    abarth: "Abarth",
    ac: "AC",
    acm: "ACM",
    acura: "Acura",
    aiways: "Aiways",
    aixam: "Aixam",
    alfaromeo: "Alfa Romeo",
    alpina: "Alpina",
    alpine: "Alpine",
    amphicar: "Amphicar",
    angelelliautomobili: "Angelelli Automobili",
    arielmotor: "Ariel Motor",
    artega: "Artega",
    aspark: "Aspark",
    aspid: "Aspid",
    astonmartin: "Aston Martin",
    aurus: "Aurus",
    austin: "Austin",
    austinhealey: "Austin-Healey",
    autobianchi: "Autobianchi",
    baic: "BAIC",
    bedford: "Bedford",
    bellier: "Bellier",
    bentley: "Bentley",
    boldmen: "Boldmen",
    bollore: "Bolloré",
    borgward: "Borgward",
    brilliance: "Brilliance",
    bristol: "Bristol",
    brute: "Brute",
    bugatti: "Bugatti",
    buick: "Buick",
    byd: "BYD",
    cadillac: "Cadillac",
    caravanswohnm: "Caravans-Wohnm",
    carver: "Carver",
    casalini: "Casalini",
    caterham: "Caterham",
    cenntro: "Cenntro",
    changhe: "Changhe",
    chatenet: "Chatenet",
    chery: "Chery",
    chevrolet: "Chevrolet",
    chrysler: "Chrysler",
    cirelli: "Cirelli",
    citroen: "Citroen",
    cityel: "CityEL",
    corvette: "Corvette",
    cupra: "CUPRA",
    dacia: "Dacia",
    daewoo: "Daewoo",
    daf: "DAF",
    daihatsu: "Daihatsu",
    daimler: "Daimler",
    dallara: "Dallara",
    dangel: "Dangel",
    delachapelle: "De la Chapelle",
    detomaso: "De Tomaso",
    delorean: "Delorean",
    devincicars: "Devinci Cars",
    dfsk: "DFSK",
    dodge: "Dodge",
    donkervoort: "Donkervoort",
    drautomobiles: "DR Automobiles",
    dsautomobiles: "DS Automobiles",
    dutton: "Dutton",
    ego: "e.GO",
    econelo: "Econelo",
    edran: "Edran",
    elaris: "Elaris",
    embuggy: "Embuggy",
    emc: "EMC",
    estrima: "Estrima",
    evetta: "Evetta",
    evo: "EVO",
    ferrari: "Ferrari",
    fiat: "Fiat",
    fisker: "FISKER",
    forthing: "Forthing",
    foton: "Foton",
    gacgonow: "Gac Gonow",
    galloper: "Galloper",
    gappy: "Gappy",
    gaz: "GAZ",
    gem: "GEM",
    gemballa: "GEMBALLA",
    genesis: "Genesis",
    giana: "Giana",
    gillet: "Gillet",
    giottivictoria: "Giotti Victoria",
    gmc: "GMC",
    goupil: "Goupil",
    greatwall: "Great Wall",
    grecav: "Grecav",
    gta: "GTA",
    gwm: "GWM",
    haima: "Haima",
    hamann: "Hamann",
    haval: "Haval",
    hiphi: "Hiphi",
    holden: "Holden",
    honda: "Honda",
    hongqi: "Hongqi",
    hummer: "HUMMER",
    hurtan: "Hurtan",
    hyundai: "Hyundai",
    ichx: "ICH-X",
    ineos: "Ineos",
    infiniti: "Infiniti",
    innocenti: "Innocenti",
    isorivolta: "Iso Rivolta",
    isuzu: "Isuzu",
    iveco: "Iveco",
    izh: "IZH",
    jac: "JAC",
    jaguar: "Jaguar",
    jeep: "Jeep",
    jensen: "Jensen",
    karma: "Karma",
    kgmobility: "KG Mobility",
    kia: "Kia",
    koenigsegg: "Koenigsegg",
    ktm: "KTM",
    lada: "Lada",
    lamborghini: "Lamborghini",
    lancia: "Lancia",
    landrover: "Land Rover",
    ldv: "LDV",
    levc: "LEVC",
    lexus: "Lexus",
    lifan: "Lifan",
    ligier: "Ligier",
    lincoln: "Lincoln",
    linzda: "Linzda",
    lorinser: "Lorinser",
    lotus: "Lotus",
    lucid: "Lucid",
    lynkco: "Lynk & Co",
    mero: "M-Ero",
    mahindra: "Mahindra",
    man: "MAN",
    mansory: "Mansory",
    martin: "Martin",
    martinmotors: "Martin Motors",
    maserati: "Maserati",
    matra: "Matra",
    maxus: "Maxus",
    maybach: "Maybach",
    mazda: "Mazda",
    mclaren: "McLaren",
    mega: "Mega",
    melex: "Melex",
    mercury: "Mercury",
    mg: "MG",
    micro: "Micro",
    microcar: "Microcar",
    militem: "Militem",
    minari: "Minari",
    minauto: "Minauto",
    mini: "MINI",
    mitsubishi: "Mitsubishi",
    mitsuoka: "Mitsuoka",
    morgan: "Morgan",
    moskvich: "Moskvich",
    mplafer: "MP Lafer",
    mpmmotors: "MPM Motors",
    nio: "NIO",
    nissan: "Nissan",
    nsu: "NSU",
    oldsmobile: "Oldsmobile",
    oldtimer: "Oldtimer",
    omoda: "Omoda",
    ora: "Ora",
    pagani: "Pagani",
    pantherwestwinds: "Panther Westwinds",
    peugeot: "Peugeot",
    pgo: "PGO",
    piaggio: "Piaggio",
    plymouth: "Plymouth",
    polestar: "Polestar",
    pontiac: "Pontiac",
    porsche: "Porsche",
    proton: "Proton",
    puch: "Puch",
    ram: "RAM",
    regis: "Regis",
    reliant: "Reliant",
    rollsroyce: "Rolls-Royce",
    rover: "Rover",
    ruf: "Ruf",
    saab: "Saab",
    santana: "Santana",
    seat: "SEAT",
    segway: "Segway",
    selvo: "Selvo",
    seres: "Seres",
    sevic: "Sevic",
    sgs: "SGS",
    shelby: "Shelby",
    shuanghuan: "Shuanghuan",
    silence: "Silence",
    singer: "Singer",
    skoda: "Skoda",
    skywell: "Skywell",
    smart: "smart",
    speedart: "SpeedArt",
    sporteequipe: "Sportequipe",
    spyker: "Spyker",
    ssangyong: "SsangYong",
    streetscooter: "StreetScooter",
    studebaker: "Studebaker",
    subaru: "Subaru",
    suzuki: "Suzuki",
    talbot: "Talbot",
    tasso: "Tasso",
    tata: "Tata",
    tazzariev: "Tazzari EV",
    techart: "TECHART",
    tesla: "Tesla",
    togg: "Togg",
    townlife: "Town Life",
    toyota: "Toyota",
    trabant: "Trabant",
    traileranhaenger: "Trailer-Anhänger",
    triumph: "Triumph",
    truckslkw: "Trucks-Lkw",
    tvr: "TVR",
    uaz: "UAZ",
    vandenplas: "Vanden Plas",
    vanderhall: "Vanderhall",
    vaz: "VAZ",
    vem: "VEM",
    vinfast: "VinFast",
    volvo: "Volvo",
    voyah: "Voyah",
    wartburg: "Wartburg",
    weltmeister: "Weltmeister",
    wenckstern: "Wenckstern",
    westfield: "Westfield",
    wey: "Wey",
    wiesmann: "Wiesmann",
    xbus: "XBus",
    xev: "XEV",
    xpeng: "Xpeng",
    zastava: "Zastava",
    zaz: "ZAZ",
    zeekr: "Zeekr",
    zhidou: "Zhidou",
    zotye: "Zotye",
    inne: "Inne"
};

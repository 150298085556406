import {applyMiddleware, compose, createStore, Store} from "redux";

import {addAlert} from "../../src/app/actions/add_remove_alert_action";
import {alertFetchDataError} from "../../src/app/constants/alert_text";
import {hybridReducer} from "../../src/app/reducers/hybrid_reducer";
import {isBrowser, isDevelopment} from "../../src/app/utils/read_environment_variables";
import {promiseMiddleware} from "../../src/app/utils/request_response_utils/promiseMiddleware";

let store: Store | null;
if (isBrowser) {
    const initialState = (window as any).__INITIAL_STATE__ || {};
    const composeEnhancers =
        (isDevelopment &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25})) ||
        compose;
    const finalCreateStore = composeEnhancers(
        // Middleware you want to use in development:
        applyMiddleware(
            promiseMiddleware({
                errorAlertAction: () => addAlert(alertFetchDataError),
                notifyError: (error) => console.error(error)
            })
        )
    )(createStore);
    store = finalCreateStore(hybridReducer, initialState);
} else {
    store = null;
}
export const getClientStore = () => store;

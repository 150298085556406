import {useEffect, useRef} from "react";

export const useTraceUpdate = (props: any) => {
    const prev: any = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps: any, [k, v]: any) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log("Changed props:", changedProps);
        }
        prev.current = props;
    });
};

import * as React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import createCache from "@emotion/cache";
import {Store} from "redux";

import {EMOTION_CACHE_KEY} from "../../src/app/constants/emotion_cache_key";
import {CarsCommonProviders} from "../../src/app/cars_common_providers";
import {IStore} from "../../src/app/reducers/hybrid_reducer";
import {getClientStore} from "./get_client_store";

const emotionCache = createCache({key: EMOTION_CACHE_KEY});

export const createHydrateApp = (providerStore: Store<IStore>, elem: Element, appElement: JSX.Element) => {
    return hydrateRoot(
        elem,
        <Provider store={providerStore}>
            <CarsCommonProviders emotionCache={emotionCache} userAgent={navigator.userAgent}>
                <BrowserRouter>{appElement}</BrowserRouter>
            </CarsCommonProviders>
        </Provider>
    );
};

export const renderSimpleApp = (elem: Element, component: JSX.Element) => {
    const store = getClientStore();
    if (!store) {
        return;
    }

    const root = createRoot(elem);
    root.render(
        <Provider store={store}>
            <CarsCommonProviders emotionCache={emotionCache} userAgent={navigator.userAgent}>
                {component}
            </CarsCommonProviders>
        </Provider>
    );
};

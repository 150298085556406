import {filter, includes} from "lodash";
import {combineReducers} from "redux";

import {fetchFavouriteCarListTypes} from "../../favourites/actions/fetch_favourite_car_list";
import {ICarListOfferResponse} from "../../meta_data/reducers/meta_data_reducer";
import {
    ILoadLocalStorageFavouritesToStore,
    ISetFavouriteCar,
    LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE,
    SET_FAVOURITE_CARS
} from "../actions/load_local_storage_favourites_to_store";
import {reduceReducers} from "../utils/reduce_reducers";
import {reduceRequestState, RequestState} from "../utils/request_response_utils/factories/reduce_request_state";
import {reduceResponse} from "../utils/request_response_utils/factories/reduce_response";

export interface IFavouritesStore {
    favourites: number[];
    favouriteCarList: ICarListOfferResponse[];
    favouriteCarListRequest: RequestState;
}

function localStorageFavouritesReducer(state: string[] = [], action: ILoadLocalStorageFavouritesToStore) {
    switch (action.type) {
        case LOAD_LOCAL_STORAGE_FAVOURITES_TO_STORE:
            return action.cars;
        default:
            return state;
    }
}

function customFavouritesReducer(state: string[] = [], action: ISetFavouriteCar) {
    switch (action.type) {
        case SET_FAVOURITE_CARS:
            return includes(state, action.car) ? filter(state, (f) => f !== action.car) : [...state, action.car];
        default:
            return state;
    }
}

export const favouritesReducer = combineReducers({
    favourites: reduceReducers(localStorageFavouritesReducer, customFavouritesReducer),
    favouriteCarList: reduceResponse(fetchFavouriteCarListTypes),
    favouriteCarListRequest: reduceRequestState(fetchFavouriteCarListTypes)
});

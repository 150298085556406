/*
`prefixPath` allows to translate app-path shorten description into full app-path string map.
 */

// we cannot change below interface because TS will protest
// eslint-disable-next-line
interface IRecord<T> extends Record<string, T> {} // because of circular dependency implementation: https://github.com/microsoft/TypeScript/pull/33050
type IAppPath = IRecord<IAppPath> | string;

export function prefixPath<TPaths>(prefix: string, paths: TPaths): TPaths {
    const res: IAppPath = {};
    for (const key in paths) {
        const path = paths[key] as unknown as IAppPath;
        if (isString(path)) {
            res[key] = prefix + path;
            continue;
        }
        if (isObject(path)) {
            res[key] = prefixPath(prefix, path as IRecord<IAppPath>);
            continue;
        }
        throw new Error(`Path has to be string or object: ${path}`);
    }
    return res as unknown as TPaths;
}

const isString = (val: unknown): boolean => typeof val === "string" || val instanceof String;
const isObject = (val: unknown): boolean => typeof val === "object" && val !== null;

import {RequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
export interface IAction {
    type: string;
}

export interface ILatestQueryAction extends IAction {
    latestQuery: {[key: string]: string | string[]};
}

export const reduceLatestQuery =
    (requestTypes: RequestActionTypes) =>
    (state: Record<string, string | string[]> = {}, action: ILatestQueryAction): Record<string, string | string[]> => {
        switch (action.type) {
            case requestTypes.start:
                return action.latestQuery;
            default:
                return state;
        }
    };

type Primitive = string | number;

interface ThemeType {
    [key: string]: ThemeType | Primitive;
}

export type PropertyStringPath<T, Prefix = ""> = {
    [K in keyof T]: T[K] extends Primitive | Array<any>
        ? `${string & Prefix}${string & K}`
        : `${string & Prefix}${string & K}` | PropertyStringPath<T[K], `${string & Prefix}${string & K}-`>;
}[keyof T];

/*
 * function returns a string containing css variables and values, ie:
 * ```
 * --colors-brand_success: #a55
 * --colors-gray_brighter: #b0b
 * ...etc.
 * ```
 *
 * This can not be imported, otherwise babel will have some transformation issues. Just copy it like it's 1990
 */
const transformThemeToCssVariables = (theme: ThemeType, base = "--"): string =>
    Object.entries(theme).reduce((css, [key, value]) => {
        const newBase = base + `${key}`;
        if (typeof value !== "object") {
            return css + newBase + `: ${value};\n`;
        }
        return css + transformThemeToCssVariables(value, newBase + "-");
    }, ``);

/**
 * factory creates a convenient method to use theme variables inside css and styled components
 */
export const cssVariableFactory = <ThemeType>() => {
    return (path: PropertyStringPath<ThemeType>) => {
        if (path.match("breakpoint")) {
            throw new Error("cssVariableFactory: css variables cannot be used in media queries");
        }

        // suppress error, because TS cries that this type is recursive
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newPath = path.replace(".", "-");
        return `var(--${newPath})`;
    };
};

/**
 * factory to create a getter for theme breakpoints
 * @param breakpoints
 */
export const cssBreakpointFactory =
    <BreakpointsType extends Record<string, string | Record<string, any>>>(breakpoints: BreakpointsType) =>
    (): BreakpointsType => {
        return breakpoints;
    };

import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";

import {createLocationSlug} from "../../utils/create_location_slug";
import {validateLocationSlugs} from "../../utils/validate_location_slugs";
import {LocationTypes} from "../url_utils/get_offer_list_url";
import {ISearchResultRouteParams} from "./redirect_constraints";
export interface ILocation {
    // id: string;
    // created_at: string;
    // updated_at: string;
    name?: string;
    // name_declension_about: string;
    // name_declension_what: string;
    // name_declension_where: string;
    slug: string;
    // path: {
    //     name: string;
    //     slug: string;
    //     type: string;
    // }[];
    //location_type: LocationTypes;
}

export interface ILocationShort {
    name: string;
    slug: string;
    type: string;
}

export const fetchAndValidateLocationBySlugType = createRequestActionTypes({name: "locationFetchValidateBySlug", type: "GET", view: "cars_list"});

export const fetchAndValidateLocationBySlugAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const {location, subLocation} = route.params;

        // validate location slug
        const isLocationSlugValid = validateLocationSlugs(location, subLocation);
        if (!isLocationSlugValid) {
            return dispatch({type: "redirectOrEnable404ResponseState", payload: route.pathname});
            //return dispatch(redirectOrEnable404ResponseState(services, route.pathname));
        }

        // fetch location data
        if (location) {
            const locationSlug = createLocationSlug(location, subLocation);

            if (locationSlug === getState().carList.location.location?.slug) {
                // prevent refetching the same location
                return Promise.resolve(getState().carList.location.location);
            }

            //TODO: temporary pass location to store
            dispatch({
                type: fetchAndValidateLocationBySlugType.success,
                result: {
                    slug: locationSlug
                    // location_type: LocationTypes //TODO: trzeba dodac validacje lokalizacji i zwrotke z istotnymi danymi
                }
            });
            return {
                slug: locationSlug
                // location_type: LocationTypes //TODO: trzeba dodac validacje lokalizacji i zwrotke z istotnymi danymi
            };

            // return fetchLocationBySlug(
            //     services,
            //     locationSlug,
            //     fetchAndValidateLocationBySlugType,
            //     "fetchAndValidateLocationBySlugType"
            // )(dispatch)
            //     .catch(
            //         catch404(async () => {
            //             // await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
            //             dispatch({type: RESPONSE_STATE_ENABLE_301, payload: route.pathname});
            //             return Promise.resolve(false);
            //         })
            //     )
            //     .catch(catchAbortedError(() => false));
        }

        // all Poland
        dispatch({type: fetchAndValidateLocationBySlugType.reset});
        return Promise.resolve(true);
    };

import {safelyParsePage} from "@rw/string_utils";
import {isEqual} from "lodash";
import {Dispatch} from "redux";

import {appPath} from "../../../../../../packages/utils/cars_routes";
import {fetchMakesDataForSearch} from "../../../homepage/actions/fetch_makes_data_for_search";
import {updateHomepageMetaData} from "../../../homepage/actions/update_homepage_meta_data";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {setCarDetailViewTypeAtRoute} from "../../../offer/action/offer_view_type_action";
import {fetchCarsDetailsAtRoute} from "../../../offer/detail/actions/fetch_cars_details_at_route";
import {fetchLatestCarListAtRoute} from "../../../offer/list/actions/fetch_latest_cars_list";
import {fetchAndValidateLocationBySlugAtRoute, ILocation} from "../../../offer/list/actions/fetch_location_by_slug_at_route";
import {ISearchResultRouteParams, redirectConstraintsAtRoute} from "../../../offer/list/actions/redirect_constraints";
import {setViewModeForOfferDetail} from "../../../offer/list/actions/set_view_mode_for_offer_detail";
import {updateCarListMetaData} from "../../../offer/list/actions/updateCarsListMetaData";
import {getParamsByTag, IOfferListFetchParams} from "../../../offer/list/url_utils/tag_utils";
import {IStore} from "../../reducers/hybrid_reducer";
import {IServices} from "../../services/IServices";
import {appendQueryString} from "../../utils/append_query_string";
import {createRequestActionTypes} from "../../utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../utils/request_response_utils/request";
import {apiLink} from "../api_link";
import {mapActions, reduceActions, strictMapActions} from "./action_helpers";
import {createAppPathDataFetcher, IRouteFetch, IRouteState} from "./create_app_path_data_fetcher";

export const fetchCarListTypes = createRequestActionTypes({name: "fetch", type: "GET", view: "car_list"});

export interface ICarsResponse {
    results: ICarListOfferResponse[];
}
export interface IOfferListApiResponse<T> {
    data: T[];
    //   meta: IOfferListApiResponseMeta;
}
export interface IOfferListResponseSSR<T> extends IOfferListApiResponse<T> {
    //    collectionCount: number;
    pageCount: number;
    page: number;
}

//TODO : to move from here  searchFormFields, FormValuesType, IRouteData,, getOfferListFetchParams
export enum FormFieldType {
    // deprecated component-based FormFieldTypes
    Checkbox = "checkbox",
    Input = "input",
    InputRange = "input-range",
    Radio = "radio",
    Select = "select",
    SelectRange = "select-range",
    Textarea = "textarea",
    SelectRangeNumber = "select-range-number",
    PhoneField = "phone-field",
    FeatureList = "feature-list"
}

export const searchFormFields = {
    search: FormFieldType.Input
    // offer_type: FormFieldType.SearchOfferType,
    // deal_type: FormFieldType.Select,
};

export type FormValuesType = Record<keyof typeof searchFormFields, any>;

interface IRouteData {
    state?: {searchFormValues?: FormValuesType};
    query: Record<string, string | string[]>;
    params: ISearchResultRouteParams;
}
export const defaultOffersParams = {
    offer_type: "osobowe",
    page: "1",
    is_active: true
};
export function urlParamsValidator(params: Partial<any>, additionalKeys: string[] = []): Partial<any> {
    const keys = ["price__gte", "price__lte", "size__gte", "size__lte", ...additionalKeys];

    return keys.reduce((acc, curr) => {
        const value = params[curr];
        if (value) {
            return {
                ...acc,
                [`${curr}`]: value
            };
        }
        return acc;
    }, {});
}
//TODO to be moved getOfferListFetchParams
export const getCarsOfferListFetchParams = (routeData: IRouteData, location: ILocation | null, search_form_offer_type?: string): IOfferListFetchParams => {
    const query = {
        ...routeData.query
    };

    const paramsFromTag = getParamsByTag(routeData.params.offerType, routeData.params.tag);

    return {
        ...defaultOffersParams,
        ...query,
        type: routeData.params.type,
        ...urlParamsValidator(routeData.query, ["name", "geo_point"]),
        ...paramsFromTag,
        ...(location && location.slug ? {location: location.slug} : {})
    };
};

export const fetchOfferListWithUniqueParams =
    (services: Partial<IServices>, params: IOfferListFetchParams, onSuccess?: (offersResponse: ICarListOfferResponse[]) => void) =>
    (dispatch: Dispatch, getState: () => IStore): Promise<void | null | IOfferListResponseSSR<ICarListOfferResponse>> => {
        // guard against refetching with the same query
        const {latestQuery} = getState().carList;
        // ignore `mapView` param that is used to determine if map view is active, we don't want to trigger fetches because it changed
        const {...validParams} = params;
        //  console.log("validParams", validParams);
        if (isEqual(validParams, latestQuery)) {
            return Promise.resolve(null);
        }
        return dispatch(fetchCarList(services, params, onSuccess));
    };

export const fetchCarListAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        //const location = getState().carList.location.location?.slug;
        const location = getState().carList.location.location;
        const params = getCarsOfferListFetchParams(route, location);
        //  console.log("PARAMS", params);
        //const params = {type: route.params.type, location: location};

        // const parsedPage = params.page ? safelyParsePage(params.page) : 1;
        const parsedPage = 1;

        const result = await dispatch(fetchOfferListWithUniqueParams(services, params));
        if (result && result.pageCount < parsedPage && parsedPage > 1) {
            const {page, ...queryWithoutPage} = route.query;
            const firstPageUrl = appendQueryString(route.pathname, queryWithoutPage);
            //  dispatch(enable301ResponseState(firstPageUrl));
            return null;
        }
        return result;
    };

//fetchCarListAtRoute
const fetchCarList =
    (services: Partial<IServices>, params: IOfferListFetchParams, onSuccess?: (offersResponse: ICarListOfferResponse[]) => void) =>
    (dispatch: Dispatch, getState: () => IStore) => {
        dispatch({type: fetchCarListTypes.start, latestQuery: params});
        // console.log("params", params);

        const url = appendQueryString(apiLink.cars.base({})(null), {type: params.type, location: params.location, ...params, ss: "aB2a"});
        //   console.log("url =", url);
        return getRequest(services, url, "fetchCarList")
            .then((response: IOfferListApiResponse<ICarListOfferResponse>) => {
                if (response) {
                    const result: IOfferListResponseSSR<ICarListOfferResponse> = {
                        // meta: response.meta || fallbackMeta,
                        data: response.data,
                        pageCount: 999,
                        page: (params.page && safelyParsePage(params.page)) || 1
                    };
                    dispatch({type: fetchCarListTypes.success, result: result});
                    return result;
                }
            })
            .catch((err) => {
                console.log("Error", err);
                return null;
            });
    };

const FETCH_ROUTES: IRouteFetch<IStore>[] = [
    {
        path: appPath.mainPage,
        exact: false,
        // matches all routes, triggers additional asynchronous promise chain
        // fetch: reduceActions(() => {
        //     console.log("fetcher");
        //     // Zwracanie funkcji, która zwraca pustą obietnicę
        //     return (dispatch, getState) => {
        //         return new Promise((resolve, reject) => {
        //             // Tutaj można dodać kod asynchroniczny, jeśli potrzebny
        //             // resolve();
        //         });
        //     };
        // }),
        routes: [
            {
                path: appPath.mainPage,
                fetch: mapActions(fetchMakesDataForSearch, updateHomepageMetaData, fetchLatestCarListAtRoute)
                //fetch: mapActions(setHomepageViewTypeAtRoute, updateHomepageMetaData)
            },
            {
                path: appPath.offer.detail.base,
                fetch: reduceActions(
                    //     // validateOfferDetailFetchAtRoute,
                    //     // openOfferModalAtRoute,
                    strictMapActions(fetchCarsDetailsAtRoute),
                    //     // updateOfferMetaData,
                    setCarDetailViewTypeAtRoute
                )
            },
            {
                path: [appPath.searchResult.universalTag, appPath.searchResult.universal],
                fetch: reduceActions(
                    /*TODO fetching cars data by location ?? */
                    fetchAndValidateLocationBySlugAtRoute,
                    redirectConstraintsAtRoute,
                    strictMapActions(
                        fetchCarListAtRoute,
                        fetchMakesDataForSearch

                        //     resetClientOnlySearchFormAtRoute
                    ),
                    updateCarListMetaData,
                    // setOfferListViewTypeAtRoute,
                    setViewModeForOfferDetail
                ),
                exact: true
            }
        ]
    }
];

export const appPathDataFetcher = createAppPathDataFetcher<IStore>(FETCH_ROUTES);

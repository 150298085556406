import {css} from "@emotion/react";
import {carsTheme} from "@rw/global_styles";
import {darken} from "polished";

// sizes
export const xxxs = ".1rem";
export const xxs = ".2rem";
export const xs = ".3rem";
export const sm = ".5rem";
export const md = "1rem";
export const lg = "1.5rem";
export const xl = "2rem";
export const xxl = "2.5rem";
export const xxxl = "3rem";

// font
export const fwb = css`
    font-weight: 700;
`;
export const fwsb = css`
    font-weight: 600;
`;
export const fwm = css`
    font-weight: 500;
`;
export const fwn = css`
    font-weight: 400;
`;
export const fwl = css`
    font-weight: 300;
`;

export const fs10 = css`
    font-size: 1rem;
`;
export const fs12 = css`
    font-size: 1.2rem;
`;
export const fs14 = css`
    font-size: 1.4rem;
`;
export const fs16 = css`
    font-size: 1.6rem;
`;
export const fs18 = css`
    font-size: 1.8rem;
`;
export const fs20 = css`
    font-size: 2rem;
`;
export const fs22 = css`
    font-size: 2.2rem;
`;

export const lh1_8 = css`
    line-height: 1.8;
`;

// text
export const tal = css`
    text-align: left;
`;
export const tac = css`
    text-align: center;
`;
export const tar = css`
    text-align: right;
`;
export const tdu = css`
    text-decoration: underline;
`;
export const tdlt = css`
    text-decoration: line-through;
`;
export const tdn = css`
    text-decoration: none;
`;

export const ttl = css`
    text-transform: lowercase;
`;

export const ttu = css`
    text-transform: uppercase;
`;

// vertical align
export const vat = css`
    vertical-align: top;
`;
export const vab = css`
    vertical-align: bottom;
`;
export const vam = css`
    vertical-align: middle;
`;

// displays
export const df = css`
    display: flex;
`;
export const db = css`
    display: block;
`;
export const dib = css`
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
`;
export const di = css`
    display: inline;
`;
export const dn = css`
    display: none;
`;

// positions
export const psa = css`
    position: absolute;
`;
export const psf = css`
    position: fixed;
`;
export const psr = css`
    position: relative;
`;
export const pss = css`
    position: sticky;
`;

export const vc = css`
    top: 50%;
    transform: translate(0%, -50%);
`;
export const hc = css`
    left: 50%;
    transform: translate(-50%, 0%);
`;
export const vhc = css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const t_1 = css`
    top: -1px;
`;
export const t0 = css`
    top: 0;
`;
export const t1 = css`
    top: 1px;
`;

export const r_1 = css`
    right: -1px;
`;
export const r0 = css`
    right: 0;
`;
export const r1 = css`
    right: 1px;
`;

export const b_1 = css`
    bottom: -1px;
`;
export const b0 = css`
    bottom: 0;
`;
export const b1 = css`
    bottom: 1px;
`;

export const l_1 = css`
    left: -1px;
`;
export const l0 = css`
    left: 0;
`;
export const l1 = css`
    left: 1px;
`;

//color classes
export const c_text = css`
    color: ${carsTheme.colors.text_color};
    fill: ${carsTheme.colors.text_color};

    &:hover {
        color: ${carsTheme.colors.text_color};
        fill: ${carsTheme.colors.text_color};
    }
`;

export const c_link = css`
    color: ${carsTheme.colors.link_color};
    fill: ${carsTheme.colors.link_color};
`;

export const as_link = css`
    color: ${carsTheme.colors.link_color};
    text-decoration: none;

    &:hover,
    &:focus {
        color: ${carsTheme.colors.link_color_hover};
        text-decoration: underline;
    }
`;

export const c_white = css`
    color: #fff;
    fill: #fff;
`;

export const c_black = css`
    color: #000;
    fill: #000;
`;

export const c_primary = css`
    color: ${carsTheme.colors.brand_primary};
    fill: ${carsTheme.colors.brand_primary};
`;

export const c_success = css`
    color: ${carsTheme.colors.brand_success};
    fill: ${carsTheme.colors.brand_success};
`;

export const c_danger = css`
    color: ${carsTheme.colors.brand_danger};
    fill: ${carsTheme.colors.brand_danger};
`;

export const c_brighter = css`
    color: ${carsTheme.colors.gray_brighter};
    fill: ${carsTheme.colors.gray_brighter};
`;

export const c_bright = css`
    color: ${carsTheme.colors.gray_bright};
    fill: ${carsTheme.colors.gray_bright};
`;

export const c_gray_lighter = css`
    color: ${carsTheme.colors.gray_lighter};
    fill: ${carsTheme.colors.gray_lighter};
`;

export const c_gray_light = css`
    color: ${carsTheme.colors.gray_light};
    fill: ${carsTheme.colors.gray_light};
`;

export const c_gray = css`
    color: ${carsTheme.colors.gray};
    fill: ${carsTheme.colors.gray};
`;

export const c_gray_dark = css`
    color: ${carsTheme.colors.gray_dark};
    fill: ${carsTheme.colors.gray_dark};
`;

export const c_gray_darker = css`
    color: ${carsTheme.colors.gray_darker};
    fill: ${carsTheme.colors.gray_darker};
`;

export const bgc_white = css`
    background-color: #fff;
`;

export const bgc_black = css`
    background-color: #000;
`;

export const bgc_primary = css`
    background-color: ${carsTheme.colors.brand_primary};
`;
export const bgc_primary_hover = css`
    background-color: ${carsTheme.colors.brand_primary_hover};
`;

export const bgc_success = css`
    background-color: ${carsTheme.colors.brand_success};
`;

export const bgc_danger = css`
    background-color: ${carsTheme.colors.brand_danger};
`;

export const bgc_brighter = css`
    background-color: ${carsTheme.colors.gray_brighter};
`;

export const bgc_bright = css`
    background-color: ${carsTheme.colors.gray_bright};
`;

export const bgc_gray_lighter = css`
    background-color: ${carsTheme.colors.gray_lighter};
`;

export const bgc_gray_light = css`
    background-color: ${carsTheme.colors.gray_light};
`;

export const bgc_gray = css`
    background-color: ${carsTheme.colors.gray};
`;

export const bgc_gray_dark = css`
    background-color: ${carsTheme.colors.gray_dark};
`;

export const bgc_gray_darker = css`
    background-color: ${carsTheme.colors.gray_darker};
`;

// width
export const wa = css`
    width: auto;
    flex-basis: auto;
`;
export const w10 = css`
    width: 10%;
    flex-basis: 10%;
`;
export const w15 = css`
    width: 15%;
    flex-basis: 15%;
`;
export const w20 = css`
    width: 20%;
    flex-basis: 20%;
`;
export const w25 = css`
    width: 25%;
    flex-basis: 25%;
`;
export const w30 = css`
    width: 30%;
    flex-basis: 30%;
`;
export const w33 = css`
    width: calc(100% / 3);
    flex-basis: calc(100% / 3);
`;
export const w35 = css`
    width: 35%;
    flex-basis: 35%;
`;
export const w40 = css`
    width: 40%;
    flex-basis: 40%;
`;
export const w45 = css`
    width: 45%;
    flex-basis: 45%;
`;
export const w50 = css`
    width: 50%;
    flex-basis: 50%;
`;
export const w55 = css`
    width: 55%;
    flex-basis: 55%;
`;
export const w60 = css`
    width: 60%;
    flex-basis: 60%;
`;
export const w65 = css`
    width: 65%;
    flex-basis: 65%;
`;
export const w66 = css`
    width: calc(100% / 1.5);
    flex-basis: calc(100% / 1.5);
`;
export const w70 = css`
    width: 70%;
    flex-basis: 70%;
`;
export const w75 = css`
    width: 75%;
    flex-basis: 75%;
`;
export const w80 = css`
    width: 80%;
    flex-basis: 80%;
`;
export const w85 = css`
    width: 85%;
    flex-basis: 85%;
`;
export const w90 = css`
    width: 90%;
    flex-basis: 90%;
`;
export const w95 = css`
    width: 95%;
    flex-basis: 95%;
`;
export const w100 = css`
    width: 100%;
    flex-basis: 100%;
`;
export const jcb = css`
    justify-content: space-between;
`;

export const h100 = css`
    height: 100%;
`;

export const h100vh = css`
    height: 100vh;
`;

// margins
export const m_a = css`
    margin: auto;
`;
export const m_0 = css`
    margin: 0;
`;
export const m_0a = css`
    margin: 0 auto;
`;

export const m_xxxs = css`
    margin: ${xxxs};
`;
export const m_xxs = css`
    margin: ${xxs};
`;
export const m_xs = css`
    margin: ${xs};
`;
export const m_sm = css`
    margin: ${sm};
`;
export const m_md = css`
    margin: ${md};
`;
export const m_lg = css`
    margin: ${lg};
`;
export const m_xl = css`
    margin: ${xl};
`;
export const m_xxl = css`
    margin: ${xxl};
`;
export const m_xxxl = css`
    margin: ${xxxl};
`;

export const mt_0 = css`
    margin-top: 0;
`;
export const mt_xxxs = css`
    margin-top: ${xxxs};
`;
export const mt_xxs = css`
    margin-top: ${xxs};
`;
export const mt_xs = css`
    margin-top: ${xs};
`;
export const mt_sm = css`
    margin-top: ${sm};
`;
export const mt_md = css`
    margin-top: ${md};
`;
export const mt_lg = css`
    margin-top: ${lg};
`;
export const mt_xl = css`
    margin-top: ${xl};
`;
export const mt_xxl = css`
    margin-top: ${xxl};
`;
export const mt_xxxl = css`
    margin-top: ${xxxl};
`;
export const mt_a = css`
    margin-top: auto;
`;

export const mr_xxxs = css`
    margin-right: ${xxxs};
`;
export const mr_xxs = css`
    margin-right: ${xxs};
`;
export const mr_xs = css`
    margin-right: ${xs};
`;
export const mr_sm = css`
    margin-right: ${sm};
`;
export const mr_md = css`
    margin-right: ${md};
`;
export const mr_lg = css`
    margin-right: ${lg};
`;
export const mr_xl = css`
    margin-right: ${xl};
`;
export const mr_xxl = css`
    margin-right: ${xxl};
`;
export const mr_xxxl = css`
    margin-right: ${xxxl};
`;

export const mb_0 = css`
    margin-bottom: 0;
`;
export const mb_xxxs = css`
    margin-bottom: ${xxxs};
`;
export const mb_xxs = css`
    margin-bottom: ${xxs};
`;
export const mb_xs = css`
    margin-bottom: ${xs};
`;
export const mb_sm = css`
    margin-bottom: ${sm};
`;
export const mb_md = css`
    margin-bottom: ${md};
`;
export const mb_lg = css`
    margin-bottom: ${lg};
`;
export const mb_xl = css`
    margin-bottom: ${xl};
`;
export const mb_xxl = css`
    margin-bottom: ${xxl};
`;
export const mb_xxxl = css`
    margin-bottom: ${xxxl};
`;

export const ml_xxxs = css`
    margin-left: ${xxxs};
`;
export const ml_xxs = css`
    margin-left: ${xxs};
`;
export const ml_xs = css`
    margin-left: ${xs};
`;
export const ml_sm = css`
    margin-left: ${sm};
`;
export const ml_md = css`
    margin-left: ${md};
`;
export const ml_lg = css`
    margin-left: ${lg};
`;
export const ml_xl = css`
    margin-left: ${xl};
`;
export const ml_xxl = css`
    margin-left: ${xxl};
`;
export const ml_xxxl = css`
    margin-left: ${xxxl};
`;

export const mv_0 = css`
    margin-top: 0;
    margin-bottom: 0;
`;
export const mv_xxxs = css`
    margin-top: ${xxxs};
    margin-bottom: ${xxxs};
`;
export const mv_xxs = css`
    margin-top: ${xxs};
    margin-bottom: ${xxs};
`;
export const mv_xs = css`
    margin-top: ${xs};
    margin-bottom: ${xs};
`;
export const mv_sm = css`
    margin-top: ${sm};
    margin-bottom: ${sm};
`;
export const mv_md = css`
    margin-top: ${md};
    margin-bottom: ${md};
`;
export const mv_lg = css`
    margin-top: ${lg};
    margin-bottom: ${lg};
`;
export const mv_xl = css`
    margin-top: ${xl};
    margin-bottom: ${xl};
`;
export const mv_xxl = css`
    margin-top: ${xxl};
    margin-bottom: ${xxl};
`;
export const mv_xxxl = css`
    margin-top: ${xxxl};
    margin-bottom: ${xxxl};
`;

export const mh_0 = css`
    margin-left: 0;
    margin-right: 0;
`;
export const mh_a = css`
    margin-left: auto;
    margin-right: auto;
`;
export const mh_xxxs = css`
    margin-left: ${xxxs};
    margin-right: ${xxxs};
`;
export const mh_xxs = css`
    margin-left: ${xxs};
    margin-right: ${xxs};
`;
export const mh_xs = css`
    margin-left: ${xs};
    margin-right: ${xs};
`;
export const mh_sm = css`
    margin-left: ${sm};
    margin-right: ${sm};
`;
export const mh_md = css`
    margin-left: ${md};
    margin-right: ${md};
`;
export const mh_lg = css`
    margin-left: ${lg};
    margin-right: ${lg};
`;
export const mh_xl = css`
    margin-left: ${xl};
    margin-right: ${xl};
`;
export const mh_xxl = css`
    margin-left: ${xxl};
    margin-right: ${xxl};
`;
export const mh_xxxl = css`
    margin-left: ${xxxl};
    margin-right: ${xxxl};
`;

// paddings
export const p_0 = css`
    padding: 0;
`;
export const p_xxxs = css`
    padding: ${xxxs};
`;
export const p_xxs = css`
    padding: ${xxs};
`;
export const p_xs = css`
    padding: ${xs};
`;
export const p_sm = css`
    padding: ${sm};
`;
export const p_md = css`
    padding: ${md};
`;
export const p_lg = css`
    padding: ${lg};
`;
export const p_xl = css`
    padding: ${xl};
`;
export const p_xxl = css`
    padding: ${xxl};
`;
export const p_xxxl = css`
    padding: ${xxxl};
`;

export const pt_xxxs = css`
    padding-top: ${xxxs};
`;
export const pt_xxs = css`
    padding-top: ${xxs};
`;
export const pt_xs = css`
    padding-top: ${xs};
`;
export const pt_sm = css`
    padding-top: ${sm};
`;
export const pt_md = css`
    padding-top: ${md};
`;
export const pt_lg = css`
    padding-top: ${lg};
`;
export const pt_xl = css`
    padding-top: ${xl};
`;
export const pt_xxl = css`
    padding-top: ${xxl};
`;
export const pt_xxxl = css`
    padding-top: ${xxxl};
`;

export const pr_xxxs = css`
    padding-right: ${xxxs};
`;
export const pr_xxs = css`
    padding-right: ${xxs};
`;
export const pr_xs = css`
    padding-right: ${xs};
`;
export const pr_sm = css`
    padding-right: ${sm};
`;
export const pr_md = css`
    padding-right: ${md};
`;
export const pr_lg = css`
    padding-right: ${lg};
`;
export const pr_xl = css`
    padding-right: ${xl};
`;
export const pr_xxl = css`
    padding-right: ${xxl};
`;
export const pr_xxxl = css`
    padding-right: ${xxxl};
`;

export const pb_xxxs = css`
    padding-bottom: ${xxxs};
`;
export const pb_xxs = css`
    padding-bottom: ${xxs};
`;
export const pb_xs = css`
    padding-bottom: ${xs};
`;
export const pb_sm = css`
    padding-bottom: ${sm};
`;
export const pb_md = css`
    padding-bottom: ${md};
`;
export const pb_lg = css`
    padding-bottom: ${lg};
`;
export const pb_xl = css`
    padding-bottom: ${xl};
`;
export const pb_xxl = css`
    padding-bottom: ${xxl};
`;
export const pb_xxxl = css`
    padding-bottom: ${xxxl};
`;

export const pl_xxxs = css`
    padding-left: ${xxxs};
`;
export const pl_xxs = css`
    padding-left: ${xxs};
`;
export const pl_xs = css`
    padding-left: ${xs};
`;
export const pl_sm = css`
    padding-left: ${sm};
`;
export const pl_md = css`
    padding-left: ${md};
`;
export const pl_lg = css`
    padding-left: ${lg};
`;
export const pl_xl = css`
    padding-left: ${xl};
`;
export const pl_xxl = css`
    padding-left: ${xxl};
`;
export const pl_xxxl = css`
    padding-left: ${xxxl};
`;

export const pv_0 = css`
    padding-top: 0;
    padding-bottom: 0;
`;
export const pv_xxxs = css`
    padding-top: ${xxxs};
    padding-bottom: ${xxxs};
`;
export const pv_xxs = css`
    padding-top: ${xxs};
    padding-bottom: ${xxs};
`;
export const pv_xs = css`
    padding-top: ${xs};
    padding-bottom: ${xs};
`;
export const pv_sm = css`
    padding-top: ${sm};
    padding-bottom: ${sm};
`;
export const pv_md = css`
    padding-top: ${md};
    padding-bottom: ${md};
`;
export const pv_lg = css`
    padding-top: ${lg};
    padding-bottom: ${lg};
`;
export const pv_xl = css`
    padding-top: ${xl};
    padding-bottom: ${xl};
`;
export const pv_xxl = css`
    padding-top: ${xxl};
    padding-bottom: ${xxl};
`;
export const pv_xxxl = css`
    padding-top: ${xxxl};
    padding-bottom: ${xxxl};
`;

export const ph_0 = css`
    padding-left: 0;
    padding-right: 0;
`;
export const ph_xxxs = css`
    padding-left: ${xxxs};
    padding-right: ${xxxs};
`;
export const ph_xxs = css`
    padding-left: ${xxs};
    padding-right: ${xxs};
`;
export const ph_xs = css`
    padding-left: ${xs};
    padding-right: ${xs};
`;
export const ph_sm = css`
    padding-left: ${sm};
    padding-right: ${sm};
`;
export const ph_md = css`
    padding-left: ${md};
    padding-right: ${md};
`;
export const ph_lg = css`
    padding-left: ${lg};
    padding-right: ${lg};
`;
export const ph_xl = css`
    padding-left: ${xl};
    padding-right: ${xl};
`;
export const ph_xxl = css`
    padding-left: ${xxl};
    padding-right: ${xxl};
`;
export const ph_xxxl = css`
    padding-left: ${xxxl};
    padding-right: ${xxxl};
`;

// others
export const truncateParent = css`
    display: table;
    table-layout: fixed;
    width: 100%;
`;
export const truncate = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
export const imgResponsive = css`
    display: block;
    max-width: 100%;
    height: auto;
`;
export const overflow_v = css`
    overflow: visible;
`;
export const overflow_h = css`
    overflow: hidden;
`;
export const overflow_y_h = css`
    overflow-y: hidden;
`;
export const overflow_x_h = css`
    overflow-x: hidden;
`;
export const overflow_y_a = css`
    overflow-y: auto;
`;
export const overflow_x_a = css`
    overflow-x: auto;
`;
export const break_word = css`
    word-wrap: break-word;
    overflow-wrap: break-word;
`;
export const break_all = css`
    word-break: break-all;
`;
export const nowrap = css`
    white-space: nowrap;
`;
export const pre_line = css`
    white-space: pre-line;
`;
export const pre = css`
    white-space: pre;
`;
export const curp = css`
    cursor: pointer;
`;
export const curd = css`
    cursor: default;
`;
export const curna = css`
    cursor: not-allowed;
`;

export const usn = css`
    user-select: none;
`;

// opacity
export const o0 = css`
    opacity: 0;
`;
export const o25 = css`
    opacity: 0.25;
`;
export const o50 = css`
    opacity: 0.5;
`;
export const o75 = css`
    opacity: 0.75;
`;

// flex
export const fg0 = css`
    flex-grow: 0;
`;
export const fg1 = css`
    flex-grow: 1;
`;
export const fs0 = css`
    flex-shrink: 0;
`;
export const fs1 = css`
    flex-shrink: 1;
`;
export const fw = css`
    flex-wrap: wrap;
`;

// aspectRadio
export const aspect_ratio_16_9 = css`
    &:before {
        content: "";
        display: block;
        padding-top: 56.25%;
    }
`;

export const aspect_ratio_1_1 = css`
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
`;

export const aspect_ratio_4_3 = css`
    &:before {
        content: "";
        display: block;
        padding-top: 75%;
    }
`;

export const aspect_ratio_80_53 = css`
    &:before {
        content: "";
        display: block;
        padding-top: 66.13%;
    }
`;

// list
export const list_unstyled = css`
    padding-left: 0;
    list-style: none;
`;

// pretty thin scroll
export const prettyScroll = (scrollbarTrackBg?: string, scrollbarThumbBg?: string) => css`
    padding-right: 0.1rem;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.3);
        background-color: ${scrollbarTrackBg ? scrollbarTrackBg : "#fff"};
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollbarThumbBg ? scrollbarThumbBg : "#ccc"};
        transition: background-color 0.2s ease-in-out;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${darken(0.06, scrollbarThumbBg ? scrollbarThumbBg : "#ccc")};
    }
`;

export const textEllipsis = (numberOfLines: number) => css`
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: ${numberOfLines};
    -webkit-box-orient: vertical;
    white-space: normal;
`;

import {every} from "lodash";
import {Dispatch} from "redux";

import {IServices} from "../../services/IServices";
import {IFetchAction, IFetchActionPure, IPrevRouteState, IRouteState} from "./create_app_path_data_fetcher";

/**
 * synchronously dispatch all actions. All actions receive result of previous action
 */
export const reduceActions =
    (...fetchActions: IFetchAction<any>[]): IFetchAction<any> =>
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: any = null) =>
    (dispatch: Dispatch) => {
        return new Promise(async (resolve, reject) => {
            let idx = 0;
            let result: any = prevActionResult;
            while (result && idx < fetchActions.length) {
                try {
                    result = await dispatch(fetchActions[idx++](services, route, prevRoute, result));
                } catch (error) {
                    return reject(error);
                }
            }
            return resolve(result);
        });
    };

/**
 * asynchronously dispatch all actions
 */
export const mapActions =
    (...fetchActions: IFetchAction<any>[]): IFetchAction<any> =>
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: any = null) =>
    (dispatch: Dispatch) => {
        return Promise.all(fetchActions.map((action) => dispatch(action(services, route, prevRoute, prevActionResult))));
    };

/**
 * return true when all actions returned truthy value
 */
export const strictMapActions =
    (...fetchActions: IFetchAction<any>[]): IFetchAction<any> =>
    (services: Partial<IServices>, route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: any = null) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        const allResponses = await Promise.all(fetchActions.map((action) => dispatch(action(services, route, prevRoute, prevActionResult))));
        return every(allResponses, (res) => res !== false);
    };

/**
 * Pure actions - without services
 */
export const reduceActionsPure =
    (...fetchActions: IFetchActionPure<any>[]): IFetchActionPure<any> =>
    (route: IRouteState<{}>, prevRoute: IPrevRouteState | null, prevActionResult: any = null) =>
    (dispatch: Dispatch) => {
        return new Promise(async (resolve, reject) => {
            let idx = 0;
            let result: any = prevActionResult;
            while (result && idx < fetchActions.length) {
                try {
                    result = await dispatch(fetchActions[idx++](route, prevRoute, result));
                } catch (error) {
                    return reject(error);
                }
            }
            return resolve(result);
        });
    };

import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {apiLink} from "../../../app/routes/api_link";
import {IOfferListApiResponse, IOfferListResponseSSR} from "../../../app/routes/data_fetcher/app_path_data_fetcher";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {ICarListOfferResponse} from "../../../meta_data/reducers/meta_data_reducer";
import {ISearchResultRouteParams} from "./redirect_constraints";

export const fetchLatestCarListTypes = createRequestActionTypes({name: "fetch-latest", type: "GET", view: "car_list-latest"});

export const fetchLatestCarListAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        dispatch({type: fetchLatestCarListTypes.start});

        const url = appendQueryString(apiLink.cars.latest({})(null), {});
        return getRequest(services, url, "fetchLatestCarList")
            .then((response: IOfferListApiResponse<ICarListOfferResponse>) => {
                if (response) {
                    const result: IOfferListResponseSSR<ICarListOfferResponse> = {
                        // meta: response.meta || fallbackMeta,
                        data: response.data,
                        pageCount: 999,
                        page: 1
                    };
                    dispatch({type: fetchLatestCarListTypes.success, result: result});
                    return result;
                }
            })
            .catch((err) => {
                console.log("Error", err);
                return null;
            });
    };

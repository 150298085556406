import {useDispatch} from "react-redux";

import {INotification} from "../../components/Notification";
import {Notifications} from "../../components/Notifications";
import {removeNotification} from "./add_remove_notification_action";

interface IProps {
    notifications: INotification[];
}

export const NotificationsReduxContainer = (props: IProps) => {
    const dispatch = useDispatch();

    const removeNotificationCallback = (id: string) => dispatch(removeNotification(id));

    return <Notifications notifications={props.notifications} removeNotification={removeNotificationCallback} />;
};

import {Dispatch} from "redux";

import {IStore} from "../../app/reducers/hybrid_reducer";
import {ViewType} from "../../tracking/view_type/view_type";
export const SET_VIEW_TYPE = "SET_VIEW_TYPE";

const setViewType = (viewType: ViewType) => async (dispatch: Dispatch) => {
    dispatch({type: SET_VIEW_TYPE, current: viewType});
    return true;
};

export const setCarDetailViewTypeAtRoute = () => async (dispatch: Dispatch, getState: () => IStore) => {
    const viewType = getState().offer.offerDetailViewType === "offerList" ? ViewType.OFFER_IN_LISTING : ViewType.OFFER;

    return dispatch(setViewType(viewType));
};

import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import styled from "@emotion/styled";
import {pluralize} from "@rw/string_utils";
import {carsTheme} from "@rw/themes";
import {ErrorMessage, Field, Form, Formik, FormikHandlers} from "formik";
import {debounce, omit, omitBy} from "lodash";
import * as Yup from "yup";

import {Spinner} from "../app/components/Spinner";
import {IStore} from "../app/reducers/hybrid_reducer";
import {apiLink} from "../app/routes/api_link";
import {appendQueryString} from "../app/utils/append_query_string";
import {getRequest} from "../app/utils/request_response_utils/request";
import {getOfferListUrl} from "../offer/list/url_utils/get_offer_list_url";
import {bgc_primary, bgc_primary_hover} from "../styles/helpers";

// Typy danych
interface SearchFormValues {
    make: string;
    model: string;
    typ: string;
    fuel: string;
    yearFrom: string;
}

// Wartości początkowe
const initialValues: SearchFormValues = {
    make: "",
    model: "",
    typ: "",
    fuel: "",
    yearFrom: ""
};

// Walidacja
const validationSchema = Yup.object({
    make: Yup.string(),
    model: Yup.string(),
    typ: Yup.string(),
    fuel: Yup.string(),
    yearFrom: Yup.string()
});

const FormWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: -40px auto 0 auto;
    padding: 40px;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 10;
    position: relative;
    box-shadow: 0 6px 16px #e5e5e5;
    border-radius: 8px;
`;

const StyledForm = styled(Form)`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
`;

const FormControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const Label = styled.label`
    margin-bottom: 5px;
    color: ${carsTheme.colors.gray};
`;

const Select = styled(Field)`
    padding: 8px;
    font-size: 16px;
    border: 2px solid ${carsTheme.colors.gray_light};
    border-radius: 4px;
    background: #fff;
    color: ${carsTheme.colors.gray};
`;

const ErrorMessageWrapper = styled.div`
    color: red;
    font-size: 12px;
    margin-top: 5px;
    position: absolute;
    bottom: -20px;
`;

const SubmitButton = styled.button`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 26px;
    font-size: 16px;
    color: #fff;
    ${bgc_primary};
    border: 2px solid ${carsTheme.colors.brand_primary};
    border-radius: 4px;
    cursor: pointer;
    min-width: 126px;
    height: 42px;
    &:hover {
        ${bgc_primary_hover};
        color: ${carsTheme.colors.brand_black};
        background: #fff;
    }
`;

const SubmitButtonWrapper = styled.div`
    display: flex;
    align-items: end;
    justify-content: center;
`;

const SearchIcon = styled.span`
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9.5 3A6.5 6.5 0 1 0 9.5 16 6.5 6.5 0 1 0 9.5 3zm0 1A5.5 5.5 0 1 1 9.5 14 5.5 5.5 0 1 1 9.5 4zm0 1A4.5 4.5 0 1 0 9.5 13 4.5 4.5 0 1 0 9.5 5zm8.486 8.629a1 1 0 0 1 1.415 0l3.518 3.519a1 1 0 0 1-1.415 1.414l-3.518-3.518a1 1 0 0 1 0-1.415z"/></svg>');
    background-size: cover;
    margin-left: 10px;
`;

type HandleSelectChangeType = (event: ChangeEvent<HTMLSelectElement>, handleChange: FormikHandlers["handleChange"], values: SearchFormValues) => void;

const CarSearchFormMobile: React.FC = () => {
    const carsAllMakes = useSelector((store: IStore) => store.carList.cars.allMakes);
    const [allSearchData, setAllSearchData] = useState({
        count: 0,
        model: [],
        body_type: [],
        fuel: [],
        yearFrom: []
    });
    const [isLoading, setIsLoading] = React.useState(false);

    const handleSubmit = async (values: SearchFormValues) => {
        const cleanedValues = omitBy(values, (value) => value === "");
        const url = getOfferListUrl({
            offerType: "osobowe",
            type: values.make,
            ...omit(cleanedValues, "make")
        });
        return (window.location.href = decodeURIComponent(url));
    };

    const handleSelectChange: HandleSelectChangeType = (event, handleChange) => {
        handleChange(event);
    };

    const debouncedAction = useCallback(
        debounce((values: SearchFormValues) => {
            setIsLoading(true);

            const cleanedValues = omitBy(values, (value) => value === "");
            //only values with some data
            //  console.log("Debounced cleanedValues:", cleanedValues);

            const url = appendQueryString(apiLink.carDynamicSearch({})({}), {...cleanedValues});
            return getRequest({}, url, "fetchDynamicSearch").then(async (json: any) => {
                setIsLoading(false);
                setAllSearchData({count: json.count, model: json.model, body_type: json.body_type, fuel: json.engine, yearFrom: json.year});
            });
        }, 400),
        []
    );
    const pluralOffer = pluralize(["oferta", "oferty", "ofert"]);

    return (
        <Formik<SearchFormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({values, handleChange}) => {
                useEffect(() => {
                    debouncedAction(values as unknown as SearchFormValues); // Ensure this is inside the render
                    return () => {
                        debouncedAction.cancel();
                    };
                }, [values, debouncedAction]);

                return (
                    <FormWrapper>
                        <StyledForm>
                            <FormControl>
                                <Label htmlFor="make">Marka</Label>
                                <Select
                                    as="select"
                                    id="make"
                                    name="make"
                                    value={values.make}
                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                        handleSelectChange(event, handleChange, values);
                                    }}
                                >
                                    <option value="">Wybierz</option>
                                    {carsAllMakes.map((make) => (
                                        <option key={make.make} value={make.make.toLowerCase()}>
                                            {`${make.make} (${make.count})`}
                                        </option>
                                    ))}
                                </Select>
                                <ErrorMessage name="make" component={ErrorMessageWrapper} />
                            </FormControl>

                            <FormControl>
                                <Label htmlFor="model">Model</Label>
                                <Select as="select" id="model" name="model" value={values.model} onChange={handleChange}>
                                    <option value="">Wybierz</option>
                                    {allSearchData.model.map((model, index) => (
                                        <option key={`${model}_${index}`} value={model}>
                                            {model}
                                        </option>
                                    ))}
                                </Select>
                                <ErrorMessage name="model" component={ErrorMessageWrapper} />
                            </FormControl>

                            <FormControl>
                                <Label htmlFor="typ">Typ</Label>
                                <Select as="select" id="typ" name="typ" value={values.typ} onChange={handleChange}>
                                    <option value="">Wybierz</option>
                                    {allSearchData.body_type.map((body, index) => (
                                        <option key={`${body}_${index}`} value={body}>
                                            {body}
                                        </option>
                                    ))}
                                </Select>
                                <ErrorMessage name="typ" component={ErrorMessageWrapper} />
                            </FormControl>

                            <FormControl>
                                <Label htmlFor="fuel">Paliwo</Label>
                                <Select as="select" id="fuel" name="fuel" value={values.fuel} onChange={handleChange}>
                                    <option value="">Wybierz</option>
                                    {allSearchData.fuel.map((fuel, index) => (
                                        <option key={`${fuel}_${index}`} value={fuel}>
                                            {fuel}
                                        </option>
                                    ))}
                                </Select>
                                <ErrorMessage name="fuel" component={ErrorMessageWrapper} />
                            </FormControl>

                            <FormControl>
                                <Label htmlFor="yearFrom">Rocznik od:</Label>
                                <Select as="select" id="yearFrom" name="yearFrom" value={values.yearFrom} onChange={handleChange}>
                                    <option value="">Wybierz</option>
                                    {allSearchData.yearFrom.map((yearFrom, index) => (
                                        <option key={`${yearFrom}_${index}`} value={yearFrom}>
                                            {yearFrom}
                                        </option>
                                    ))}
                                </Select>
                                <ErrorMessage name="yearFrom" component={ErrorMessageWrapper} />
                            </FormControl>

                            <SubmitButtonWrapper>
                                <SubmitButton type="submit">
                                    {isLoading ? <Spinner /> : "Wyszukaj"}
                                    {allSearchData && <small>{`${allSearchData.count} ${pluralOffer(allSearchData.count)}`}</small>}
                                    {/*<SearchIcon />*/}
                                </SubmitButton>
                            </SubmitButtonWrapper>
                        </StyledForm>
                    </FormWrapper>
                );
            }}
        </Formik>
    );
};

export default CarSearchFormMobile;

import * as _ from "lodash";

const ERROR_400 = "Bad Request";
const ERROR_401 = "Unauthorized";
const ERROR_403 = "Forbidden";
const ERROR_404 = "Not Found";
const ERROR_504 = "Gateway Timeout";
const ERROR_5XX = "Server Error";
const ERROR_UNKNOWN_STATUS = "Unknown Status Error";
const ERROR_TIMEOUT = "Response Timeout";

const ERROR_UNKNOWN_ERROR = "Unknown Error";

export interface IAppError {
    status: number;
    fieldErrors: Record<string, string[]>;
    nonFieldErrors: string[];
}

export class ResponseError {
    public name: string;
    public message: string;
    public response: Response;

    constructor(name: string, message: string, response: Response) {
        this.name = name;
        this.message = message;
        this.response = response;
    }
}

export class Response400 extends ResponseError {
    public appError?: IAppError;

    constructor(response: Response, message: string) {
        super(ERROR_400, message, response);
    }
}

export class Response401 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_401, message, response);
    }
}

export class Response403 extends ResponseError {
    public responseError?: Object;

    constructor(response: Response, message: string) {
        super(ERROR_403, message, response);
    }
}

export class Response404 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_404, message, response);
    }
}
export class Response504 extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_504, message, response);
    }
}

export class Response5xx extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_5XX, message, response);
    }
}

export class ResponseUnknownStatus extends ResponseError {
    constructor(response: Response, message: string) {
        super(ERROR_UNKNOWN_STATUS, message, response);
    }
}

export class ResponseTimeoutError {
    public name: string;
    public url: string;

    constructor(url: string) {
        this.name = ERROR_TIMEOUT;
        this.url = url;
    }
}

export class ResponseUnknownError {
    public name: string;
    public message: string;
    public originalError: Error;

    constructor(err: Error, message: string) {
        this.name = ERROR_UNKNOWN_ERROR;
        this.message = message;
        this.originalError = err;
    }
}

/**
 * Class used to mark AbortController rejections
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */
export class RequestAbortedError {
    public reason: string;

    constructor(reason: string) {
        this.reason = reason;
    }
}
// When signal.abort() is called, the promise rejects with a DOMException Error, with name "AbortError".
export const REQUEST_ABORTED_ERROR_NAME = "AbortError";

/**
 * Catch helpers
 */
const createCatchResponseError =
    <TError extends {name?: string; reason?: string}>(ErrorClass: Function) =>
    <TReturn = unknown>(catchCallback: (err: TError) => TReturn) =>
    (err: TError): TReturn => {
        if (err instanceof ErrorClass) {
            return catchCallback(err);
        } else {
            // throw error to be handled down the promise execution path
            throw err;
        }
    };

export const catch400 = createCatchResponseError<Response400>(Response400);
export const catch401 = createCatchResponseError<Response401>(Response401);
export const catch403 = createCatchResponseError<Response403>(Response403);
export const catch404 = createCatchResponseError<Response404>(Response404);
export const catch504 = createCatchResponseError<Response504>(Response504);
export const catch5xx = createCatchResponseError<Response5xx>(Response5xx);
export const catchUnknownStatus = createCatchResponseError<ResponseUnknownStatus>(ResponseUnknownStatus);
export const catchUnknownError = createCatchResponseError<ResponseUnknownError>(ResponseUnknownError);
export const catchTimeout = createCatchResponseError<ResponseTimeoutError>(ResponseTimeoutError);
export const catchAbortedError = createCatchResponseError<RequestAbortedError>(RequestAbortedError);

/**
 * Przujmuje status odpowiedzi HTTP i JSON tej odpowiedzi.
 * Zwraca obiekt zawierający: status odpowiedzi, błędy pól formularza, błędy ogólne.
 */
function getAppErrors(status: number, responseJson: any): IAppError {
    const appError: IAppError = {
        status,
        fieldErrors: {},
        nonFieldErrors: []
    };
    _.each(responseJson, (err: string[], key: string) => {
        if (key === "non_field_errors") {
            appError.nonFieldErrors = err;
        } else {
            appError.fieldErrors[key] = err;
        }
    });
    return appError;
}

export const parseResponseErrors = (request: Promise<any>): Promise<any> =>
    request
        .catch(
            catch400(async (err) => {
                const json = await err.response.json();
                err.appError = getAppErrors(err.response.status, json);
                throw err;
            })
        )
        .catch(
            catch403(async (err) => {
                err.responseError = await err.response.json();
                throw err;
            })
        );

export enum ViewType {
    HOMEPAGE = "homepage",
    ABOUT_US = "about_us",
    BLOG = "blog",
    OFFER = "offer",
    OFFER_IN_LISTING = "offer_in_listing",
    FAVOURITES = "favourites"
}

// here we keep only GTM specified values
export const getViewTypeName = (viewType: ViewType): string => {
    switch (viewType) {
        case ViewType.HOMEPAGE:
            return "Strona główna";
        case ViewType.ABOUT_US:
            return "O nas";
        case ViewType.BLOG:
            return "Blog";
        case ViewType.OFFER_IN_LISTING:
            return "Widok oferty - listing";
        case ViewType.OFFER:
            return "Widok oferty";
        default:
            return "Inne";
    }
};

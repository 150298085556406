import styled from "@emotion/styled";

interface Container {
    width?: string;
}

export const PageContainer = styled.article<Container>`
    position: relative;
    margin: 0 auto;
    max-width: 100%;

    @media screen and (min-width: 1230px) {
        width: ${(props) => (props.width ? props.width : "1200px")};
    }
`;

// Supported alert types
export enum AlertType {
    SUCCESS = 1,
    INFO = 2,
    WARNING = 3,
    ERROR = 4
}

// Single alert timeout in miliseconds
export const defaultAlertTimeout = 3000;

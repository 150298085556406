import {ViewType} from "./view_type";
import {ISetViewTypeAction,  SET_VIEW_TYPE} from "./view_type_actions";

export interface IViewTypeStore {
    current: ViewType | null;
    previous: ViewType | null;
}

const initialState: IViewTypeStore = {
    current: null,
    previous: null
};

export const viewTypeReducer = (state: IViewTypeStore = initialState, action: ISetViewTypeAction): IViewTypeStore => {
    switch (action.type) {
        case SET_VIEW_TYPE:
            return {
                current: action.current,
                previous: state.current
            };
        default:
            return state;
    }
};

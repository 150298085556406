const ENV = process.env;
// NODE_ENV - defines production build and production code execution
export const isProduction: boolean = ENV.NODE_ENV === "production";
export const isDevelopment: boolean = ENV.NODE_ENV === "development";

if (!isProduction && !isDevelopment) {
    throw new Error("env: NODE_ENV is not properly defined");
}

// EXEC_ENV - defines execution environment (browser or server)
export const isBrowser: boolean = ENV.EXEC_ENV === "browser";
export const isServer: boolean = ENV.EXEC_ENV === "server";

if (!isBrowser && !isServer) {
    throw new Error("env: EXEC_ENV is not properly defined");
}

declare global {
    interface Window {
        __SERVER_ENV__: Record<string, any>; // Replace `any` with a specific type if possible
    }
}

const UNIVERSAL_ENV = isBrowser ? window.__SERVER_ENV__ : process.env;

// CARS_COMMON_URL - defines current CARS site used to display full URL
export const carsCommonUrl = readRequiredEnvVariable("CARS_COMMON_URL");

export const carsApiUrl = readRequiredEnvVariable("API_URL");
export const carsCookieDomain: string = (UNIVERSAL_ENV.CARS_COOKIE_DOMAIN ?? null) as string;
export const gtmId: string = UNIVERSAL_ENV.GTM_ID;

/*
 * Browser Env
 */
export const browserEnvs = {
    CARS_COMMON_URL: carsCommonUrl,
    API_URL: carsApiUrl,
    CARS_COOKIE_DOMAIN: carsCookieDomain,
    GTM_ID: gtmId
};

/**
 * Reads variable from environment. Throws if not defined
 * @param name: environment variable name
 */
function readRequiredEnvVariable(name: string): string {
    const envVariable = UNIVERSAL_ENV[name] ?? null;
    if (envVariable == null) {
        throw new Error(`env: ${name} is not defined`);
    }
    return envVariable;
}

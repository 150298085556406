import {loadLocalStorageFavouritesToStore} from "../../src/app/actions/load_local_storage_favourites_to_store";
import {Error500Dynamic} from "../../src/app/components/Error500Dynamic";
import {GHNotifications} from "../../src/app/components/GHNotifications";
import {NotFoundDynamic} from "../../src/app/components/NotFoundDynamic";
import {NavigationMain} from "../../src/navigation/NavigationMain";
import {createHydrateApp} from "./create_hydrate_app";
import {getClientStore} from "./get_client_store";

import "../../src/styles/linaria_global_styles_cars";

const store = getClientStore();
export const prepareHydratedApp = async (config: any) => {
    if (!store) {
        return;
    }
    await store.dispatch(loadLocalStorageFavouritesToStore());

    hydrateAllViews(store, config);
};

const defaultConfig = [
    {reactDynamic: "error-500-dynamic", view: <Error500Dynamic />},
    {reactDynamic: "navigation", view: <NavigationMain />},
    {reactDynamic: "notifications", view: <GHNotifications />},
    {reactDynamic: "not-found-dynamic", view: <NotFoundDynamic />}
];

const hydrateAllViews = (store: any, config: any) => {
    const componentHooks = document.querySelectorAll("[react-dynamic]");
    const combineConfig = [...defaultConfig, ...config];

    componentHooks.forEach((element: Element) => {
        const appName = element.getAttribute("react-dynamic");
        const found = combineConfig.some((el) => {
            if (el.reactDynamic === appName) {
                createHydrateApp(store, element, el.view);
                return true;
            }
            return false;
        });

        if (!found) {
            throw new Error(`desktop-hybrid: element ${appName} not found`);
        }
    });
};

import {darken, lighten, rgba} from "polished";

//mono variable names
const brand_black = "#000000"; //black
const brand_white = "#ffffff"; //white
const brand_primary_color = "#FF5A00"; //primary
const brand_primary_color_hover = "#f57a37"; //primary
const brand_success_color = "#45bc83"; //green
const brand_danger_color = "#e81d31"; //red

const white_color = "#fff";
const black_color = "#000";
const gray_dark = "#151515";
const gray_regular = "#2C2C2C";
const gray_light = "#F7F7F7";

const gray_warmer_color = "#fbfbfb";
const gray_very_bright_color = "#eee";
const gray_bright_color = "#e3e1e1";
const gray_very_dark_color = "#ddd";
const gray_brighter_color = "#bbb";
const gray_light_color = "#b1b7bd";
const gray_lighter_color = "#999999";
const gray_color = "#909090";
const gray_base_color = "#787993";
const gray_warm_color = "#6f6d6d";
const gray_darker_color = "#536168";
const gray_dark_color = "#37474f";

const element_height_base = "4.2rem";
const element_height_large = "5rem";
const element_height_small = "3.4rem";

const line_height_base = 1.428571429;
const line_height_large = 1.3333333;
const line_height_small = 1.428571429;
const link_color = brand_primary_color;

//cars specific
const brand_primary_dark_color = "#FF5A00"; //primaryDark
const brand_primary_light_color = "#FF5A00"; //primaryLight
const brand_primary_lighter_color = "#f1ecf8"; //primaryLighter
const primary_market_color = "#00a9ff"; //primary_market
const aftermarket_color = "#ff4a38"; //aftermarket
const success_color = "#0f5132"; //success
const success_color_light = "#d1e7dd"; //success
const blue_color = "#5a9de6"; //blue
const dark_blue_color = "#0c83de"; //darkBlue
const green_color = brand_success_color; //tertiary
const green_darker_color = "#009688"; //secondary
const yellow_color = "#f3d66d"; //yellow
const navy_color = gray_base_color; //navy
const border_radius_base = ".4rem";
const border_radius_large = ".5rem";
const border_radius_small = ".3rem";
const brand_info_color = "#5a9de6";
const textarea_height_base = "10rem";

//other cars variables
const offerBoxMargin = 9;
const borderRadius = "0.4rem";
const borderRadiusSm = "0.3rem";

export const carsTheme = {
    buttons: {
        btn_default_color: white_color,
        btn_default_color_hover: darken(0.3, blue_color),
        btn_default_bg: blue_color,
        btn_default_bg_hover: darken(0.1, blue_color),
        btn_default_border: blue_color,
        btn_default_border_hover: darken(0.1, blue_color),

        btn_bright_color: gray_dark_color,
        btn_bright_color_inverted: white_color,
        btn_bright_color_inverted_hover: white_color,
        btn_bright_color_hover: gray_dark_color,
        btn_bright_bg: gray_brighter_color,
        btn_bright_bg_hover: darken(0.05, gray_brighter_color),
        btn_bright_border: gray_brighter_color,
        btn_bright_border_hover: darken(0.05, gray_brighter_color),

        btn_primary_color: white_color,
        btn_primary_color_hover: darken(0.1, brand_primary_color),
        btn_primary_bg: brand_primary_color,
        btn_primary_bg_hover: darken(0.1, brand_primary_color),
        btn_primary_border: brand_primary_color,
        btn_primary_border_hover: darken(0.1, brand_primary_color),

        btn_success_color: white_color,
        btn_success_color_hover: darken(0.1, brand_success_color),
        btn_success_bg: brand_success_color,
        btn_success_bg_hover: darken(0.1, brand_success_color),
        btn_success_border: brand_success_color,
        btn_success_border_hover: darken(0.1, brand_success_color),

        btn_danger_color: white_color,
        btn_danger_color_hover: darken(0.1, brand_danger_color),
        btn_danger_bg: brand_danger_color,
        btn_danger_bg_hover: darken(0.1, brand_danger_color),
        btn_danger_border: brand_danger_color,
        btn_danger_border_hover: darken(0.1, brand_danger_color),

        btn_dark_color: white_color,
        btn_dark_color_inverted: gray_dark_color,
        btn_dark_color_inverted_hover: darken(0.1, gray_dark_color),
        btn_dark_color_hover: darken(0.1, gray_dark_color),
        btn_dark_bg: gray_color,
        btn_dark_bg_hover: darken(0.1, gray_color),
        btn_dark_border: gray_brighter_color,
        btn_dark_border_hover: darken(0.1, gray_brighter_color),

        btn_link_color: link_color,
        btn_link_color_hover: darken(0.1, link_color),

        btn_cars_color: white_color,
        btn_cars_color_hover: darken(0.1, brand_primary_color),
        btn_cars_bg: brand_primary_color,
        btn_cars_bg_hover: darken(0.1, brand_primary_color),
        btn_cars_border: brand_primary_color,
        btn_cars_border_hover: darken(0.1, brand_primary_color),

        btn_white_color: gray_dark_color,
        btn_white_color_hover: darken(0.1, white_color),
        btn_white_bg: white_color,
        btn_white_bg_hover: darken(0.1, white_color),
        btn_white_border: white_color,
        btn_white_border_hover: darken(0.1, white_color),

        btn_transition: "background-color .2s ease-in-out, color .2s ease-in-out, border-color .2s ease-in-out",
        btn_transition_icon: "fill .2s ease-in-out",
        btn_cursor_disabled: "not-allowed",
        btn_font_weight: 500,
        btn_height_base: element_height_base,
        btn_height_large: element_height_large,
        btn_height_small: element_height_small,

        btn_border_radius_base: border_radius_base,
        btn_border_radius_large: border_radius_large,
        btn_border_radius_small: border_radius_small
    },
    borders: {
        border_radius_base: border_radius_base,
        border_radius_large: border_radius_large,
        border_radius_small: border_radius_small
    },
    checkboxes: {
        checkbox_transition: "border 0.2s ease-in-out, background .2s ease-in-out",
        checkbox_transition_in_list: "border 0.2s ease-in-out, background .2s ease-in-out",
        checkbox_border_radius: border_radius_base,
        checkbox_icon_color: white_color,
        checkbox_icon_width_base: "16px",
        checkbox_icon_width_large: "30px",
        checkbox_icon_width_small: "13px",
        checkbox_icon_box_width_base: "16px",
        checkbox_icon_box_width_large: "40px",
        checkbox_icon_box_width_small: "15px",
        checkbox_icon_height_base: "16px",
        checkbox_icon_height_large: "30px",
        checkbox_icon_height_small: "13px",
        checkbox_icon_box_height_base: "16px",
        checkbox_icon_box_height_large: "40px",
        checkbox_icon_box_height_small: "15px",
        checkbox_icon_border: "1px solid #bbbbbb",
        checkbox_icon_margin_right: "1.2rem",
        checkbox_icon_margin_top: "0.2rem",
        checkbox_label_font_size_base: "1.2rem",
        checkbox_label_font_size_large: "1.4rem",
        checkbox_label_font_size_small: "1rem",
        checkbox_label_color: gray_color,
        checkbox_outline: `2px solid ${brand_success_color}`,
        checkbox_focus_box_shadow: `0 0 0 1px rgba(red(${brand_success_color}), green(${brand_success_color}), blue(${brand_success_color}), 0.5)`,
        checkbox_icon_border_color: brand_success_color,
        checkbox_icon_border_color_active: brand_success_color,
        checkbox_icon_background_color_active: brand_success_color,

        checkbox_btn_icon_color: gray_dark_color,
        checkbox_btn_background: gray_lighter_color,
        checkbox_btn_border: `1px solid ${gray_color}`,
        checkbox_btn_border_radius: "4px",
        checkbox_btn_font_size: "1.2rem",
        checkbox_btn_font_weight: "500",
        checkbox_btn_padding: "1.2rem 3rem",
        checkbox_btn_icon_left_padding: "0.8rem",

        checkbox_inlist_font_size_base: "1.2rem",
        checkbox_inlist_label_color: gray_dark_color,
        checkbox_inlist_transition: "background .2s ease-in-out",
        checkbox_inlist_background_active: gray_lighter_color,
        checkbox_inlist_background_in_range: gray_lighter_color,
        checkbox_inlist_padding_base: "1.2rem",
        checkbox_inlist_padding_large: "1.6rem",
        checkbox_inlist_padding_small: "1rem",
        checkbox_inlist_padding_vertical: "1.5rem 2rem",

        checkbox_inlist_line_height: "1.6rem",

        checkbox_list_border: `1px solid ${gray_color}`,
        checkbox_list_child_border_right: `1px solid ${gray_color}`,
        checkbox_list_border_radius: border_radius_base
    },
    colors: {
        //brand
        brand_black: brand_black,
        brand_white: brand_white,
        brand_primary: brand_primary_color,
        brand_primary_hover: brand_primary_color_hover,
        brand_success: brand_success_color,
        brand_danger: brand_danger_color,

        //grays
        gray_light: gray_light,
        gray: gray_regular,
        gray_dark: gray_dark,

        //to delete
        gray_very_bright: gray_very_bright_color,
        gray_brighter: gray_brighter_color,
        gray_bright: gray_bright_color,
        gray_lighter: gray_lighter_color,

        gray_darker: gray_darker_color,
        gray_very_dark: gray_very_dark_color,
        gray_warm: gray_warm_color,
        gray_warmer: gray_warmer_color,

        //links
        link_color: blue_color,
        link_color_hover: darken(0.1, blue_color),
        link_green_color: brand_success_color,
        link_green_color_hover: darken(0.15, brand_success_color),

        //project specific variables
        brand_primary_dark: brand_primary_dark_color,
        brand_primary_light: brand_primary_light_color,
        brand_primary_lighter: brand_primary_lighter_color,
        primary_market: primary_market_color,
        aftermarket: aftermarket_color,
        success_color: success_color,
        success_color_light: success_color_light,
        blue: blue_color,
        dark_blue: dark_blue_color,
        green: green_color,
        green_darker: green_darker_color,
        yellow: yellow_color,
        navy: navy_color,
        body_bg: "#fff",
        text_color: gray_dark_color,
        text_white: white_color,

        //gallery tabs
        tab_gallery: "#5a9de6",
        tab_virtual_tour: "#e34298",
        tab_map: "#45bc83",
        tab_street_view: "#f2a423",
        tab_education_poi: "#9639ed",
        tab_transport_poi: "#0d32a8",
        tab_shopping_poi: "#b51a30",

        //filters
        filter_active_bg: rgba(brand_primary_color, 0.15),
        filter_active_hover_bg: rgba(brand_primary_color, 0.25),
        filter_active_bg_light: lighten(0.35, brand_primary_color)
    },
    fonts: {
        font_family: "Nunito Sans, sans-serif",
        font_size_root: "10px",
        font_size_base: "1.4rem",
        font_size_big: "1.6rem",
        font_size_large: "1.5rem",
        font_size_small: "1.2rem",
        font_size_tiny: "0.8rem",
        font_size_h1: "2.2rem",
        font_size_h2: "2rem",
        font_size_h3: "1.8rem",
        line_height_base: line_height_base,
        line_height_large: line_height_large,
        line_height_small: line_height_small,
        font_weight_base: 400
    },
    breakpoints: {
        screen_xxs: "480px",
        screen_xs: "600px", // used only in Features.tsx
        screen_sm: "768px", //ipad
        screen_md: "1024px", //desktop
        screen_lg: "1300px", //desktopNormal
        screen_lg_ipad: "1366px", // ipad pro
        screen_xl: "1685px", //desktopWide
        screen_1600: "1600px", // custom used in Footer.tsx
        numeric: {
            screen_xs: 480,
            screen_sm: 768,
            screen_md: 1024,
            screen_lg: 1200,
            screen_xl: 1400
        }
    },
    breakpoints_correct: {
        screen_xxs: "360px",
        screen_xs: "480px",
        screen_sm: "768px",
        screen_md: "1024px",
        screen_lg: "1200px",
        screen_xl: "1400px",
        screen_xxl: "1600px",
        screen_xxxl: "1920px",
        numeric: {
            screen_xxs: 360,
            screen_xs: 480,
            screen_sm: 768,
            screen_md: 1024,
            screen_lg: 1200,
            screen_xl: 1400,
            screen_xxl: 1600,
            screen_xxxl: 1920
        }
    },
    forms: {
        input_bg: "#fff",
        input_bg_disabled: gray_lighter_color,
        input_color: gray_dark_color,
        input_border: gray_brighter_color,
        input_font_size_base: "1.4rem",
        input_font_size_large: "1.6rem",
        input_font_size_small: "1.2rem",
        input_box_shadow: "none",
        input_box_shadow_focus: "none",
        input_border_radius: border_radius_base,
        input_border_radius_large: border_radius_large,
        input_border_radius_small: border_radius_small,
        input_border_focus: brand_info_color,
        input_color_placeholder: gray_darker_color,
        input_height_base: element_height_base,
        input_height_large: element_height_large,
        input_height_small: element_height_small,
        form_group_margin_bottom: "2rem",

        input_transition: "border-color .2s ease-in-out, box-shadow .2s ease-in-out",
        input_cursor_disabled: "not-allowed",
        input_font_weight: 400,

        textarea_height: textarea_height_base,

        error_message_color: brand_danger_color,
        error_message_input_box_shadow: `inset 0 0 0 1px ${brand_danger_color}`,
        error_message_font_size: "1rem",
        error_message_line_height: 1.5,
        error_message_padding_bottom: ".5rem",

        label_color: black_color,
        label_font_weight: 600,
        label_font_size: "1.3rem",
        label_vertical_margin: ".5rem",
        label_horizontal_margin: "2rem",
        label_attached_on_border_background_color: white_color,

        select_option_font_size: "1.4rem",
        select_option_bg: "transparent",
        select_option_bg_hover: gray_brighter_color,
        select_option_bg_selected: gray_lighter_color,
        select_option_bg_selected_hover: darken(0.05, gray_lighter_color),
        select_multi_value_bg: brand_primary_color,
        select_multi_value_border_radius: "3rem"
    },
    other: {
        offer_box_margin: offerBoxMargin,
        border_radius: borderRadius,
        border_radius_sm: borderRadiusSm
    },
    padding: {
        padding_base_vertical: ".9rem",
        padding_base_horizontal: "1.8rem",
        padding_large_vertical: "1.2rem",
        padding_large_horizontal: "2.4rem",
        padding_small_vertical: ".6rem",
        padding_small_horizontal: "1.2rem"
    },
    listing_sizes: {
        full: "100%",
        xl: "1254px",
        lg: "943px",
        md: "632px",
        sm: "325px"
    },
    icons: {
        icon_default_color: gray_dark_color
    },
    main_nav: {
        height: "7rem",
        z_index: 7002
    },
    date_picker: {
        date_picker_bg: white_color,
        date_picker_border_color: gray_light_color,
        date_picker_border_radius: border_radius_base,
        date_picker_clear_btn_color: gray_color,
        date_picker_color: gray_dark_color,
        date_picker_day_active_bg_color: brand_primary_color,
        date_picker_day_border_radius: border_radius_small,
        date_picker_day_range_bg_color: lighten(0.2, brand_primary_color),
        date_picker_day_size: "2.4rem",
        date_picker_font_size: "1.2rem",
        date_picker_header_bg: gray_lighter_color
    }
};

import styled from "@emotion/styled";
import {Property} from "csstype";

interface IFlexProps {
    justifyContent?: Property.JustifyContent;
    alignItems?: Property.AlignItems;
}

export const Flex = styled.div(
    {
        display: "flex"
    },
    (props: IFlexProps) => ({
        alignItems: props.alignItems ? props.alignItems : undefined,
        justifyContent: props.justifyContent ? props.justifyContent : undefined
    })
);

export const FlexColumn = styled(Flex)`
    flex-direction: column;
`;

export const FlexItem = styled.div``;

import {isBrowser} from "../../app/utils/read_environment_variables";
import {IStandardMetaData} from "../reducers/meta_data_reducer";

export const suffix = " - zakupauto.pl";

export function setClientMetaData(metaData: IStandardMetaData) {
    if (isBrowser) {
        // update title
        window.document.title = `${metaData.title}${metaData.hideSuffix ? "" : suffix}`;

        // update meta data
        safelyUpdateMetaTag("name", "description", metaData.description);
        safelyUpdateMetaTag("property", "og:title", window.document.title);
        safelyUpdateMetaTag("property", "og:description", metaData.description);
        metaData.canonical ? safelyUpdateLinkTag("canonical", metaData.canonical) : safelyRemoveLinkTag("canonical");
        metaData.relPrev ? safelyUpdateLinkTag("prev", metaData.relPrev) : safelyRemoveLinkTag("prev");
        metaData.relNext ? safelyUpdateLinkTag("next", metaData.relNext) : safelyRemoveLinkTag("next");
        metaData.robots ? safelyUpdateMetaTag("name", "robots", metaData.robots) : safelyRemoveMetaTag("robots");
        metaData.keywords && safelyUpdateMetaTag("name", "keywords", metaData.keywords);
    }
}

function safelyUpdateMetaTag(attributeName: string, tag: string, text: string) {
    // prepare tag
    const oldTag = window.document.querySelector(`meta[${attributeName}='${tag}']`);
    const newTag = window.document.createElement("meta");

    attributeName === "name" ? newTag.setAttribute("name", tag) : newTag.setAttribute("property", tag);
    newTag.content = text;

    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}

function safelyRemoveMetaTag(name: string) {
    const oldTag = window.document.querySelector(`meta[name=${name}]`);
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.removeChild(oldTag);
    }
}

function safelyUpdateLinkTag(name: string, text: string) {
    // prepare tag
    const oldTag = window.document.querySelector(`link[rel=${name}]`);
    const newTag = window.document.createElement("link");
    newTag.rel = name;
    newTag.href = text;
    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}

function safelyRemoveLinkTag(name: string) {
    const oldTag = window.document.querySelector(`link[rel=${name}]`);
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.removeChild(oldTag);
    }
}

import React from "react";
import {css, Theme} from "@emotion/react";

import {Flex} from "../atoms/Flex";

export const NotFoundDynamic = () => {
    return (
        <>
            <section css={notFoundHolder}>
                <Flex alignItems="center" justifyContent="space-between">
                    <div css={notFoundCopyHolder}>
                        <h1 css={notFoundHeading}>Błędny adres</h1>

                        <p css={notFoundCopy}>
                            Przepraszamy, nie możemy wyświetlić tej strony. <br />
                        </p>

                        <p css={notFoundErrorCode}>404</p>
                    </div>
                </Flex>
            </section>
        </>
    );
};

const notFoundHolder = (theme: Theme) => css`
    max-width: 1300px;
    margin: 3rem auto;
    padding: 0 1rem;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        padding: 6rem 1.5rem;
    }
`;

const notFoundCopyHolder = (theme: Theme) => css`
    max-width: 100%;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        max-width: 552px;
    }
`;

const notFoundHeading = (theme: Theme) => css`
    margin: 2rem 0 0;
    color: ${theme.colors.gray_darker};
    font-size: 45px;
    font-weight: 600;
    text-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.5);
    line-height: 1.5;

    @media (min-width: ${theme.breakpoints.screen_sm}) {
        font-size: 5.5rem;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        font-size: 6.5rem;
    }
`;

const notFoundCopy = (theme: Theme) => css`
    margin: 0.5rem 0;
    font-weight: 400;
    line-height: 1.58;
    font-size: 1.6rem;
    color: ${theme.colors.brand_primary};
`;

const notFoundErrorCode = (theme: Theme) => css`
    font-size: 3.6rem;
    line-height: 1.56;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${theme.colors.brand_primary};
`;

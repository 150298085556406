import Cookies, {CookieAttributes} from "js-cookie";

export function setCookie(name: string, value: string | string[] | number | number[] | boolean, options?: CookieAttributes): void {
    if (typeof value === "object" && typeof value === "string") {
        Cookies.set(name, value, options);
    } else {
        Cookies.set(name, value.toString(), options);
    }
}

export function removeCookie(name: string) {
    Cookies.remove(name);
}

export function getCookie(name: string) {
    return Cookies.get(name);
}

export const getCookieFromString = (name: string, cookieString?: string): string | undefined => {
    if (!cookieString) return;

    const value = "; " + cookieString;
    const parts = value.split("; " + name + "=");
    return parts.length >= 2 ? (parts.pop() as string).split(";").shift() : undefined;
};

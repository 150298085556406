import {match, matchPath} from "react-router";
import {each} from "lodash";

interface IPathDefinition {
    [key: string]:
        | {
              [key: string]: string | IPathDefinition;
          }
        | string;
}

/*
 * returns a matching path from deeply nested objects i.e. constructed using prefixPath, like `apiPath`, `appPath`
 */
export const getMatchedPathDefinition = (pathsDefinition: IPathDefinition, pathName: string): string | null => {
    let result: string | null = null;
    let match: match | null = null;
    const realPathName = pathName.replace("api-proxy/", "");
    each(pathsDefinition, (path) => {
        if (typeof path === "string") {
            match = matchPath(realPathName, {path, exact: true});
            if (match) {
                result = path;
                return false; // break
            }
        } else {
            result = getMatchedPathDefinition(path, realPathName);
            if (result) {
                return false; // break
            }
        }
    });

    return result;
};

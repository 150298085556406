import {reduce} from "lodash";

import {INotification} from "../../components/Notification";
import {ADD_NOTIFICATION, DROP_TTL_AND_REMOVE, IAddNotificationAction, IRemoveNotificationAction, REMOVE_NOTIFICATION} from "./add_remove_notification_action";

export type INotificationsStore = INotification[];
export interface INotificationsStoreElement {
    notifications: INotificationsStore;
}

export const notificationsReducer = (
    state: INotificationsStore = [],
    action: IAddNotificationAction | IRemoveNotificationAction | {type: typeof DROP_TTL_AND_REMOVE}
): INotificationsStore => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            const addAction = action as IAddNotificationAction;
            const isDuplicate = state.some((n) => n.type === addAction.notification.type && n.content === addAction.notification.content);
            return isDuplicate ? state : [...state, addAction.notification];
        case REMOVE_NOTIFICATION:
            const removeAction = action as IRemoveNotificationAction;
            return state.filter((notification) => notification.id !== removeAction.id);
        case DROP_TTL_AND_REMOVE:
            return reduce<INotification, INotification[]>(
                state,
                (acc, notification) => {
                    if (notification.timeout === -1) {
                        // infinite timeout
                        return [...acc, notification];
                    }
                    // drop timeout by 1 when is high enough
                    if (notification.timeout > 1) {
                        return [...acc, {...notification, timeout: notification.timeout - 1}];
                    }
                    // filter out alert whose timeout is 1 or less
                    return acc;
                },
                []
            );
        default:
            return state;
    }
};

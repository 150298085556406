import {v4 as uuid} from "uuid";

import {INotification} from "../../components/Notification";
import {INotificationType} from "../../types";

// Single alert timeout in milliseconds
export const defaultAlertTimeout = 3000;

/**
 * Add/remove alert
 */
export const ADD_NOTIFICATION = "alerts/ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "alerts/REMOVE_NOTIFICATION";

export interface INotificationValues {
    type: INotificationType;
    content: JSX.Element | string;
}

export interface IAddNotificationAction {
    type: typeof ADD_NOTIFICATION;
    notification: INotification;
}

export interface IRemoveNotificationAction {
    type: typeof REMOVE_NOTIFICATION;
    id: string;
}

export const addNotification = (alertValue: INotificationValues, timeout = 2000): IAddNotificationAction => {
    const id = uuid();
    const notification = {...alertValue, id, timeout: timeout};
    return {type: ADD_NOTIFICATION, notification};
};
export const removeNotification = (id: string): IRemoveNotificationAction => ({type: REMOVE_NOTIFICATION, id});

/**
 * Drop TTL
 */
export const DROP_TTL_AND_REMOVE = "alerts/DROP_TTL_AND_REMOVE";
export const dropAllTtlAndRemoveInvalid = () => ({type: DROP_TTL_AND_REMOVE});

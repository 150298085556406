export interface RequestActionTypes {
    start: string;
    success: string;
    error: string;
    reset: string;
}

interface ICreateRequestActionTypesFunctionParams {
    view?: string;
    type: "GET" | "POST" | "DELETE" | "PUT";
    name: string;
}

export const createRequestActionTypes = (params: ICreateRequestActionTypesFunctionParams): RequestActionTypes => ({
    start: `${params.type}_${params.name}@${params.view || "GLOBAL"}_REQUEST_START`,
    success: `${params.type}_${params.name}@${params.view || "GLOBAL"}_REQUEST_SUCCESS`,
    error: `${params.type}_${params.name}@${params.view || "GLOBAL"}_REQUEST_ERROR`,
    reset: `${params.type}_${params.name}@${params.view || "GLOBAL"}_REQUEST_RESET`
});

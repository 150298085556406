import {css} from "@emotion/react";

interface HamburgerProps {
    isOpen: boolean;
}

export default function Hamburger({isOpen}: HamburgerProps) {
    return (
        <div css={hamburgerStyle}>
            <div css={[burgerStyle, burger1Style(isOpen)]} />
            <div css={[burgerStyle, burger2Style(isOpen)]} />
            <div css={[burgerStyle, burger3Style(isOpen)]} />
        </div>
    );
}

const hamburgerStyle = css`
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    z-index: 10;
`;

const burgerStyle = css`
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
`;

const burger1Style = (isOpen: boolean) => css`
    transform: ${isOpen ? "rotate(45deg)" : "rotate(0)"};
`;

const burger2Style = (isOpen: boolean) => css`
    transform: ${isOpen ? "translateX(100%)" : "translateX(0)"};
    opacity: ${isOpen ? 0 : 1};
`;

const burger3Style = (isOpen: boolean) => css`
    transform: ${isOpen ? "rotate(-45deg)" : "rotate(0)"};
`;

import {isEqual} from "lodash";
import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {appendQueryString} from "../../../app/utils/append_query_string";
import {isServer} from "../../../app/utils/read_environment_variables";
import {enable301ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";
import {UOfferTypes} from "../../utils/constants_offer";
import {getOfferListUrl} from "../url_utils/get_offer_list_url"; //locationtype droped
import {UMakeKeys, validateOfferListTagWithParams, validateRangeFilterParams} from "../url_utils/tag_utils";

export interface ISearchResultRouteParams {
    type?: UMakeKeys;
    offerType: UOfferTypes;
    location?: string;
    subLocation?: string;
    subSubLocation?: string;
    tag?: string;
}

export const redirectConstraintsAtRoute =
    (services: Partial<IServices>, route: IRouteState<ISearchResultRouteParams>) => async (dispatch: Dispatch, getState: () => IStore) => {
        const {page: strPage, ...restQuery} = route.query;
        const intPage = parseInt(strPage as string, 10);

        // Redirect if requested url contains page=1.
        if (!isNaN(intPage) && intPage === 1) {
            dispatch(enable301ResponseState(appendQueryString(route.pathname, restQuery)));
            return false; // stop data-fetcher
        }

        // for tag/params -related redirects
        const currentParams = {...route.params, ...route.query};
        // console.log("currentParams", currentParams);
        //   const locationType = getState().offerList.location.location?.location_type;
        const validatedParams = validateOfferListTagWithParams(currentParams);
        const validatedRangeFilterParams = validateRangeFilterParams(validatedParams);

        if (isServer && !isEqual(currentParams, validatedRangeFilterParams)) {
            // current params are invalid, redirect
            dispatch(enable301ResponseState(getOfferListUrl(validatedRangeFilterParams)));
            return false;
        }

        return true; // continue data-fetcher
    };

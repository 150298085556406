/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {carsTheme} from "@rw/themes";

import {PageContainer} from "../app/atoms/PageContainer";

import logo from "../../assets/logo-white.png";

const FooterContainer = styled.footer`
    background-color: ${carsTheme.colors.gray};
    padding: 20px 20px 40px 20px;
    color: ${carsTheme.colors.text_white};
`;
const FooterColumnContainer = styled.div`
    background-color: ${carsTheme.colors.gray};
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: ${carsTheme.colors.text_white};
`;

const FooterColumn = styled.div`
    flex: 1;
    min-width: 150px;
    margin: 10px 0;

    h4 {
        margin-bottom: 10px;
        font-size: 16px;
        color: ${carsTheme.colors.text_white};
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li {
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
        color: ${carsTheme.colors.text_white};

        &:hover {
            text-decoration: underline;
        }
    }
`;

// Zbiory danych z linkami
const aboutUsLinks = [
    {name: "Kim jesteśmy", url: "#about"},
    {name: "Nasz zespół", url: "#team"},
    {name: "Kariera", url: "#careers"},
    {name: "Aktualności", url: "#news"}
];

const servicesLinks = [
    {name: "Kup samochód", url: "#buy"},
    {name: "Sprzedaj samochód", url: "#sell"},
    {name: "Opcje finansowania", url: "#finance"},
    {name: "Obsługa klienta", url: "#support"}
];

const supportLinks = [
    {name: "FAQ", url: "#faq"},
    {name: "Kontakt", url: "#contact"},
    {name: "Opinie", url: "#feedback"},
    {name: "Regulamin", url: "#terms"}
];

const socialLinks = [
    {name: "Facebook", url: "#facebook"},
    {name: "Twitter", url: "#twitter"},
    {name: "Instagram", url: "#instagram"},
    {name: "LinkedIn", url: "#linkedin"}
];

export const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <PageContainer>
                <div css={footerLogo}>
                    <img src={logo} alt="" width={146} height={40} />
                </div>
                <FooterColumnContainer>
                    <FooterColumn>
                        <h4>O nas</h4>
                        <ul>
                            {aboutUsLinks.map((link) => (
                                <li key={link.url}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </FooterColumn>
                    <FooterColumn>
                        <h4>Usługi</h4>
                        <ul>
                            {servicesLinks.map((link) => (
                                <li key={link.url}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </FooterColumn>
                    <FooterColumn>
                        <h4>Wsparcie</h4>
                        <ul>
                            {supportLinks.map((link) => (
                                <li key={link.url}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </FooterColumn>
                    <FooterColumn>
                        <h4>Social Media</h4>
                        <ul>
                            {socialLinks.map((link) => (
                                <li key={link.url}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </FooterColumn>
                </FooterColumnContainer>
            </PageContainer>
        </FooterContainer>
    );
};

const footerLogo = css`
    padding: 25px 0 10px 0;
`;

import React, {ReactNode} from "react";
import {EmotionCache} from "@emotion/cache";
import {CacheProvider, Global, ThemeProvider} from "@emotion/react";
import {carsTheme, CarsThemeProvider} from "@rw/global_styles";
import {UserDeviceProvider} from "@rw/user-device";

import {globalStylesCars} from "../styles/global_emotion_styles_cars";

interface IProps {
    children?: ReactNode;
    emotionCache: EmotionCache;
    userAgent: string | undefined;
}

export const CarsCommonProviders: React.FC<IProps> = (props) => {
    return (
        <CacheProvider value={props.emotionCache}>
            <CarsThemeProvider>
                <ThemeProvider theme={carsTheme}>
                    <Global styles={globalStylesCars} />
                    <UserDeviceProvider userAgent={props.userAgent}>{props.children}</UserDeviceProvider>
                </ThemeProvider>
            </CarsThemeProvider>
        </CacheProvider>
    );
};

import {IQueryParams} from "../../../app/utils/append_query_string";
import {OfferType} from "../../utils/constants_offer";
import {LocationTypes} from "./get_offer_list_url";

export enum UrlTagSlug {
    STUDIO = "kawalerki"
}
export type IFeaturesTypes = SearchFeaturesTest;

export enum SearchFeaturesTest {
    TEST = "test",
    TEST2 = "test2"
}
export type ISearchPageUrlTag = {
    tag: UrlTagSlug;
    params?: IQueryParams;
    featureName?: IFeaturesTypes;
    metaText?: string;
    breadcrumbText: string;

    // params used to validate tags:
    offerType?: OfferType[]; // undefined = tag not limited to offer type
    locationTypes?: LocationTypes[]; // undefined = not limited to location type
};

/**
 * url builder will take these params into account when testing validity
 */
export const TAG_PARAMS_TO_WATCH = ["price__lte", "individual_offer", "is_private"];

/**
 * Rules below are used to validate offer URLs and when invalidated, will cause 301 redirects to other URLs.
 * Incorrect handling may result in mess that is hard to clean up.
 */
export const searchPageUrlTags: Record<string, ISearchPageUrlTag> = {
    // STUDIO: {
    //     tag: UrlTagSlug.STUDIO,
    //     params: {
    //         rooms: "1"
    //     },
    //     offerType: [OfferType.apartment],
    //     breadcrumbText: UrlTagSlug.STUDIO
    // },
    // TWO_ROOM: {
    //     tag: UrlTagSlug.TWO_ROOM,
    //     params: {
    //         rooms: "2"
    //     },
    //     metaText: UrlTagSlug.TWO_ROOM,
    //     offerType: [OfferType.apartment],
    //     breadcrumbText: UrlTagSlug.TWO_ROOM
    // }
};

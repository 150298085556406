import * as React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {map} from "lodash";

import chrome_icon from "../../styles/assets/browser_icons/chrome_icon.svg";
import edge_icon from "../../styles/assets/browser_icons/edge_icon.svg";
import firefox_icon from "../../styles/assets/browser_icons/firefox_icon.svg";
import opera_icon from "../../styles/assets/browser_icons/opera_icon.svg";
import safari_icon from "../../styles/assets/browser_icons/safari_icon.svg";

export const BrowserSupport = () => {
    const iconCollection = [
        {
            name: "Mozilla Firefox",
            url: "https://www.mozilla.org/pl/firefox/new/",
            icon: firefox_icon
        },
        {
            name: "Google Chrome",
            url: "https://www.google.com/intl/pl_pl/chrome/",
            icon: chrome_icon
        },
        {
            name: "Microsoft Edge",
            url: "https://www.microsoft.com/pl-pl/edge",
            icon: edge_icon
        },
        {
            name: "Safari",
            url: "https://support.apple.com/pl_PL/downloads/safari",
            icon: safari_icon
        },
        {
            name: "Opera",
            url: "https://www.opera.com/pl/download",
            icon: opera_icon
        }
    ];

    return (
        <BrowserSupportView>
            <GridFluid>
                <Heading>Twoja przeglądarka nie jest obsługiwana</Heading>

                <p css={paragraph}>
                    Nowa odsłona RynkuPierwotnego została stworzona w oparciu o najnowsze technologie zapewniające szybsze działanie serwisu i maksymalne
                    bezpieczeństwo. Niestety, Twoja aktualna przeglądarka nie wspiera tych technologii.
                </p>

                <p css={subParagraph}>Sugerujemy pobranie najnowszej wersji jednej z poniższych wyszukiwarek:</p>

                <div css={iconHolder}>
                    {map(iconCollection, (browser, idx) => (
                        <a href={browser.url} css={browserLink} rel="nofollow noopener" target="_blank" key={idx}>
                            <img src={browser.icon} alt={browser.name} css={browserIcon} loading="lazy" />
                            <p css={iconParagraph}>{browser.name}</p>
                        </a>
                    ))}
                </div>
            </GridFluid>
        </BrowserSupportView>
    );
};

const GridFluid = styled.section`
    min-width: 320px;
    max-width: 1190px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_md}) {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.screen_xl}) {
        max-width: 1600px;
    }
`;

const Heading = styled.div`
    margin: 0;
    padding: 3rem 0;
    line-height: 1.2;
    font-size: 2.2rem;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_xs}) {
        font-size: 3.6rem;
    }
`;

const paragraph = css`
    font-size: 1.6rem;
    margin-top: 0;
    text-align: center;
    line-height: 2.5rem;
`;

const subParagraph = css`
    ${paragraph};
    margin-bottom: 3rem;
`;

const iconParagraph = css`
    ${paragraph};
    font-size: 1.2rem;
`;

export const BrowserSupportView = styled.div`
    display: none;
    background: #e5e5e5;
    padding: 5rem 2rem;
    text-align: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10000000;
    top: 0;
    left: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.screen_xs}) {
        padding: 8rem 0 15rem;
    }
`;

const browserLink = (theme: Theme) => css`
    color: ${theme.colors.gray_dark};
    margin-right: 4.5rem;
    max-width: 55px;
    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: ${theme.colors.gray_dark};
        text-decoration: underline;
    }

    @media (min-width: ${theme.breakpoints.screen_xs}) {
        max-width: 100px;
    }
`;

const iconHolder = (theme: Theme) => css`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 4rem;
    color: ${theme.colors.text_color};

    @media (min-width: ${theme.breakpoints.screen_xs}) {
        justify-content: center;
    }
`;

const browserIcon = css`
    width: 52px;
    height: 52px;
`;

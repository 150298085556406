import {
    IActionResponseState,
    RESPONSE_STATE_DISABLE,
    RESPONSE_STATE_ENABLE_301,
    RESPONSE_STATE_ENABLE_302,
    RESPONSE_STATE_ENABLE_404,
    RESPONSE_STATE_ENABLE_ERROR
} from "./response_state_actions";

export interface ResponseStateStore {
    state: ResponseState;
    status: number;
    url?: string;
}

export enum ResponseState {
    STATE_200,
    STATE_301,
    STATE_302,
    STATE_404,
    STATE_ERROR
}

const initStore: ResponseStateStore = {
    state: ResponseState.STATE_200,
    status: 200
};

export const responseStateReducer = (state: ResponseStateStore = initStore, action: IActionResponseState): ResponseStateStore => {
    switch (action.type) {
        case RESPONSE_STATE_ENABLE_301:
            return {state: ResponseState.STATE_301, status: 301, url: action.url};
        case RESPONSE_STATE_ENABLE_302:
            return {state: ResponseState.STATE_302, status: 302, url: action.url};
        case RESPONSE_STATE_ENABLE_404:
            return {state: ResponseState.STATE_404, status: 404};
        case RESPONSE_STATE_ENABLE_ERROR:
            return {state: ResponseState.STATE_ERROR, status: action.status};
        case RESPONSE_STATE_DISABLE:
            return {state: ResponseState.STATE_200, status: 200};
        default:
            return state;
    }
};

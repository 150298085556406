import {Dispatch} from "redux";

import {appLink} from "../../../../../packages/utils/cars_routes";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {carsCommonUrl} from "../../app/utils/read_environment_variables";
import {updateMetaData} from "../../meta_data/actions/update_meta_data";

export const updateHomepageMetaData = (_1: Partial<IServices>, route: IRouteState<{}>) => (dispatch: Dispatch) => {
    const title = `Zakup Auto | Samochody Na Sprzedaż - Oferty, Używane i Nowe`;
    const description = `Znajdź samochody na sprzedaż w najlepszych cenach. Oferty aut nowych i używanych od sprzedawców i prywatnych właścicieli. Sprawdź nasze ogłoszenia i kup swoje wymarzone auto już dziś!`;
    const robots = "index, follow";
    const url = `${carsCommonUrl}${appLink.mainPage()}`;

    dispatch(updateMetaData(title, description, route, {robots, canonical: url}));

    return Promise.resolve(true);
};

import {useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {appLink} from "@rw/cars_routes";

import CarSearchFormMobile from "../search/CarSearchFormMobile";
import {getThemeBreakpoint, getThemeVariable} from "../styles/linaria_variable_factory";
import Hamburger from "./Hamburger";

import logo from "../../assets/logo.png";
export const NavigationMain = () => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
        setFilterOpen(false);
    };
    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
        setHamburgerOpen(false);
    };

    return (
        <NavigationHolder>
            <nav css={navStyle}>
                <a href={appLink.home()}>
                    <div>
                        <img src={logo} alt="" width={146} height={40} />
                    </div>
                </a>
                <ul css={(theme) => navList(theme, hamburgerOpen)}>
                    <li>
                        <a href={appLink.news()}>News</a>
                    </li>
                    <li>
                        <a href={appLink.favourites()}>Ulubione</a>
                    </li>
                </ul>

                <div css={(theme) => mobileFilters(theme, filterOpen)}>
                    <CarSearchFormMobile />
                </div>

                <div css={mobileWrapper}>
                    <div css={filterStyle} onClick={toggleFilter}>
                        Filtry
                    </div>
                    <div css={hamburger} onClick={toggleHamburger}>
                        <Hamburger isOpen={hamburgerOpen} />
                    </div>
                </div>
            </nav>
        </NavigationHolder>
    );
};

const filterStyle = (theme: Theme) => css`
    display: flex;
    margin-right: 3rem;
    align-items: center;
    z-index: 6;

    &:hover {
        cursor: pointer;
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;

const mobileWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
        z-index: 6;
    }
`;
const hamburger = (theme: Theme) => css`
    display: block;
    padding-top: 10px;
    margin-left: 10px;
    z-index: 6;

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
    }
`;

const NavigationHolder = styled.div`
    width: 100%;
    background: ${getThemeVariable("colors-brand_white")};
    height: ${getThemeVariable("main_nav-height")};
    position: relative;
    font-weight: 500;
    padding-top: 5px;

    @media (min-width: ${getThemeBreakpoint().screen_lg}) {
        z-index: ${getThemeVariable("main_nav-z_index")};
    }

    @media print {
        display: none !important;
    }
`;
const navStyle = css`
    display: flex;
    justify-content: space-between;
    padding: 9px 40px;
    box-shadow: rgba(0, 0, 0, 0.07) 0 4px 8px;
`;
const navList = (theme: Theme, hamburgerOpen: boolean) => css`
    display: ${hamburgerOpen ? "inline" : "none"};
    background-color: #ffffff;
    height: 100vh;
    width: 90vw;
    margin-top: 50px;
    position: fixed;  
    z-index: -1;
    padding-top: 5rem;
    left: ${hamburgerOpen ? "0" : "-100%"}; 
    top: 0;
    transition: left 0.3s ease-in-out;  

    li {
        list-style: none;
        padding:  12px 0;
        a {
            color: ${getThemeVariable("colors-brand_black")};
        }
    }

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: flex;
        flex-wrap: wrap;
        float: right;
        margin: 0;
        padding: 0;
        overflow: hidden;
        align-items: center;
        flex-direction: row;
        height: auto;
        width: auto;
        position: relative;
        z-index: 10;
        left: auto;
        
        li {
            padding: 0 20px;
        }   
`;

const mobileFilters = (theme: Theme, filterOpen: boolean) => css`
    display: ${filterOpen ? "inline" : "none"};
    background-color: #ffffff;
    height: 100vh;
    width: 90vw;
    margin-top: 50px;
    position: fixed;  
    z-index: -2;
    padding-top: 5rem;
    left: ${filterOpen ? "0" : "-100%"}; 
    top: 0;
    transition: left 0.3s ease-in-out;  

    @media (min-width: ${theme.breakpoints.screen_md}) {
        display: none;
   
`;

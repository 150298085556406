import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IRouteState} from "../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../app/services/IServices";
import {appendQueryString} from "../../app/utils/append_query_string";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";
import {catch404, catchAbortedError} from "../../app/utils/request_response_utils/response_error";
import {updateMetaTitleDescription} from "./update_meta_data";

interface CustomMetaDataResponse {
    title: string;
    description: string;
    heading_h1?: string;
}

export const customMetaActionTypes = createRequestActionTypes({view: "Global", type: "GET", name: "CustomMetaData"});

export type CustomMetaData = {
    title: string;
    description: string;
    appliesTo: string;
    heading_h1?: string;
};

export const fetchCustomMetaData = (services: Partial<IServices>, route: IRouteState<{}>) => async (dispatch: Dispatch) => {
    if (Object.keys(route.query).length !== 0) {
        // query should be empty to assign custom meta
        return true;
    }

    dispatch({type: customMetaActionTypes.start});

    const path = route.pathname;
    const apiUrl = appendQueryString(apiLink.metaData.page(), {path});
    return getRequest(services, apiUrl, "fetchCustomMetaData")
        .then((res: CustomMetaDataResponse) => {
            const customMeta = {
                title: res.title,
                description: res.description,
                appliesTo: path,
                heading_h1: res.heading_h1
            };

            dispatch(updateMetaTitleDescription(customMeta.title, customMeta.description));
            dispatch({type: customMetaActionTypes.success, result: customMeta});

            return customMeta;
        })
        .catch(catchAbortedError(() => false))
        .catch(
            catch404(() => {
                dispatch({type: customMetaActionTypes.reset});
                return true;
            })
        );
};

import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";
import {CloseButtonIcon} from "@rw/icons";
import {useFancyUnmount, useMounted} from "@rw/react_utils";

import {INotificationType} from "../types";

export interface INotificationProps {
    notificationData: INotification;
    removeNotificationFromStore: () => void;
}

export interface INotification {
    id: string;
    type: INotificationType;
    content: JSX.Element | string;
    timeout: number;
}

const animationDuration = 2000;

export const Notification = (props: INotificationProps) => {
    const {
        notificationData: {timeout, type, content},
        removeNotificationFromStore
    } = props;

    const isMounted = useMounted();

    const {unmount, runUnmountAnimation} = useFancyUnmount(
        animationDuration,
        () => {
            removeNotificationFromStore();
        },
        timeout
    );

    return (
        <NotificationWrap runUnmountAnimation={runUnmountAnimation} animationDuration={animationDuration} isMounted={isMounted}>
            <StyledNotification type={type}>
                <span>
                    <StyledInfoIcon type={type}>i</StyledInfoIcon>
                </span>
                <span css={contentWrap}>{content}</span>
                <div css={closeButtonWrap}>
                    <StyledCloseButton
                        type={type}
                        onClick={() => {
                            unmount();
                        }}
                    >
                        <CloseButtonIcon />
                    </StyledCloseButton>
                </div>
            </StyledNotification>
        </NotificationWrap>
    );
};

const NotificationWrap = styled.li<{runUnmountAnimation: boolean; animationDuration: number; isMounted: boolean}>`
    position: relative;
    display: flex;
    

    ${({isMounted}) =>
        isMounted
            ? css`
                  opacity: 1;
                  transition: all 1.2s;
              `
            : css`
                  opacity: 0;
              `};

    ${({runUnmountAnimation}) =>
        runUnmountAnimation
            ? css`
                  transform: translateX(100%);
                  opacity: 0;
                  transition: all 1s;
              `
            : css`
                  opacity: 1;
              `}
    }
`;

export const StyledNotification = styled.div<{type: INotificationType}>`
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    min-height: 8.6rem;
    border-radius: 0.4rem;
    padding: 3rem;
    margin: 1.5rem;

    color: #fff;

    background: ${({type, theme}) => {
        switch (type) {
            case "success":
                return theme.colors.brand_success;
            case "info":
                return theme.colors.gray;
            case "error":
                return theme.colors.brand_danger;
            default:
                return theme.colors.brand_primary;
        }
    }};
`;
const contentWrap = css`
    display: flex;
    align-items: center;
`;
const closeButtonWrap = css`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;
const StyledCloseButton = styled.div<{type: INotificationType}>`
    &:hover {
        cursor: pointer;
    }
    svg {
        width: 1.1rem;
        height: 1.1rem;
        fill: #fff;
    }
`;

const StyledInfoIcon = styled.div<{type: INotificationType}>`
    color: ${({type, theme}) => {
        switch (type) {
            case "success":
                return theme.colors.brand_success;
            case "info":
                return theme.colors.gray;
            case "error":
                return theme.colors.brand_danger;
            default:
                return theme.colors.brand_primary;
        }
    }};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    margin-right: 1rem;
`;

export type UOfferTypes = "osobowe" | "ciezarowe";

export enum VehicleType {
    CARS = "cars",
    TRUCKS = "trucks"
}

export enum OfferType {
    CARS = "osobowe",
    TRUCKS = "ciezarowe"
}

export enum OfferTypePL {
    cars = "osobowe",
    trucks = "ciężarowe"
}


export const TucksMakes = ["daf", "scania"];

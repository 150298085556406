import {IAction} from "../../../interfaces/IAction";

export interface IActionResponseState extends IAction {
    url?: string;
    status: number;
}

export const RESPONSE_STATE_ENABLE_301 = "response_state/ENABLE_301";
export const enable301ResponseState = (url: string) => ({type: RESPONSE_STATE_ENABLE_301, url});

export const RESPONSE_STATE_ENABLE_302 = "response_state/ENABLE_302";
export const enable302ResponseState = (url: string) => ({type: RESPONSE_STATE_ENABLE_302, url});

export const RESPONSE_STATE_ENABLE_404 = "response_state/ENABLE_404";
export const enable404ResponseState = () => ({type: RESPONSE_STATE_ENABLE_404});

export const RESPONSE_STATE_ENABLE_ERROR = "response_state/ENABLE_ERROR";
export const enableStatusError = (status: number) => ({type: RESPONSE_STATE_ENABLE_ERROR, status});

export const RESPONSE_STATE_DISABLE = "response_state/DISABLE";
export const disableResponseState = () => ({type: RESPONSE_STATE_DISABLE});

export const fontFace = (fontPath: string, variant: string, type: string, weight: string, style: string, display: string) => `
    @font-face {
        font-family: '${variant}';
        src: url('${fontPath}${type}.woff2') format('woff2'),
             url('${fontPath}${type}.woff') format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: ${display};
    }
`;

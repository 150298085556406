import {isEmpty} from "@rw/nodash";
import {safelyParsePage} from "@rw/string_utils";
import {Dispatch} from "redux";

import {IStore} from "../../../app/reducers/hybrid_reducer";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {CarMakeTypes} from "../../../cars_data/cars_data";
import {updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {getUrlTagDataBySlug} from "../url_utils/tag_utils";
import {ILocation} from "./fetch_location_by_slug_at_route";

interface IProps {
    offersCount: number;
    currentLocationName: ILocation | null;
    offerType: string;
    page: number;
    type?: CarMakeTypes;
    tag?: string;
}

export const getCarListTitle = (props: IProps) => {
    const {currentLocationName, type, tag, offerType, page, offersCount} = props;
    const locationTitle = currentLocationName && currentLocationName.name ? `${currentLocationName.name} ` : "Polska ";
    if (type) {
        return `Samochody osobowe ${type} na sprzedaż`;
    }

    return `${locationTitle}samochody osobowe na sprzedaż`;
};

export const getCarListDescription = (props: IProps) => {
    const {currentLocationName, type, tag, offerType, page, offersCount} = props;
    const locationTitle = currentLocationName && currentLocationName.name ? `${currentLocationName.name} ` : "Polsce ";
    if (type) {
        if (currentLocationName) {
            return `Atrakcyjne oferty samochodów ${type} w ${locationTitle} na sprzedaż`;
        }
        return `Atrakcyjne oferty samochodów ${type} na sprzedaż`;
    }

    return `Wszystkie samochody osobowe dostępne w ${locationTitle} na sprzedaż`;
};

export const updateCarListMetaData =
    (
        _1: Partial<IServices>,
        route: IRouteState<{
            offerType: string;
            type?: CarMakeTypes;
            tag?: string;
        }>
    ) =>
    (dispatch: Dispatch, getState: () => IStore) => {
        const state = getState();
        const page = safelyParsePage(route.query.page);
        const currentLocationName = state.carList.location.location;

        const title = getCarListTitle({
            offersCount: state.carList.cars.cars.length || 0,
            currentLocationName,
            offerType: route.params.offerType,
            page,
            type: route.params.type,
            tag: route.params.tag
        });

        const description = getCarListDescription({
            offersCount: state.carList.cars.cars.length || 0,
            currentLocationName,
            offerType: route.params.offerType,
            page,
            type: route.params.type,
            tag: route.params.tag
        });

        //const canonical = page === 1 ? baseUrl : urlWithPage;
        const robots = (() => {
            const offersLength = state.carList.cars.cars.length;

            const foundTag = getUrlTagDataBySlug(route.params.tag);
            const {page: pageParam, ...otherParams} = route.query;
            const hasOtherParams = !isEmpty(otherParams);

            const isPageIndexable = safelyParsePage(pageParam) < 11;
            const firstPage = safelyParsePage(pageParam) === 1;

            switch (true) {
                case offersLength > 0 && isPageIndexable:
                    return "index, follow";
                default:
                    return "noindex, follow";
            }
        })();

        dispatch(
            updateMetaData(title, description, route, {
                canonical: undefined,
                robots
            })
        );
        return Promise.resolve(true);
    };

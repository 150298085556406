import {prefixPath} from "@rw/prefix_path";
import {each, isEmpty, isPlainObject} from "lodash";
import * as pathToRegexp from "path-to-regexp";
import {number} from "yup";

import {carsApiUrl} from "../utils/read_environment_variables";

type TDataQuery<T> = {
    [P in keyof T]?: TDataQuery<T[P]> | boolean;
};

interface IApiCarsDetailParams<Type = number | string> {
    carId: Type;
}
interface IApiCarsDynamicSearchParams {
    make?: string;
}
interface ITokenParams {
    token: string;
}
interface IAuthorOffersParams {
    author_id: number;
}
interface IDeleteOffersParams {
    offer_id: number;
}
interface IAddCarParams {
    author_id: number;
    title: string;
    content?: string;
    expiry: number;
}

interface IFavParams {
    ids: number[];
}

/**
 * API path
 */

export const apiPath = prefixPath("/", {
    metaData: prefixPath("metadata/", {
        base: "",
        page: "page/"
    }),
    resetPassword: "reset-password/",
    newPassword: "new-password/",
    validateToken: "validate-token/",
    cars: prefixPath("get-cars/", {
        base: ""
    }),
    car_search: prefixPath("cars-make-search/", {
        base: ""
    }),
    carDynamicSearch: prefixPath("get-cars-search/", {
        base: ""
    }),
    latestCars: prefixPath("get_latest_cars/", {
        base: ""
    }),
    favourites: prefixPath("get-favourites/", {
        base: ""
    }),
    carsDetails: prefixPath("cars-detail/:carId([\\w-]+)/", {
        base: ""
    }),
    userOffers: prefixPath("cars-by-author/", {
        base: ""
    }),
    addCar: prefixPath("add-car/", {
        base: ""
    }),
    carData: prefixPath("car/", {
        base: "",
        delete: "delete"
    }),
    siteMap: "sitemap.json"
});

/**
 * API link
 */

export const apiLink = {
    metaData: {
        page: createLinkNoParams(apiPath.metaData.page)
    },
    cars: {
        base: createLink(apiPath.cars.base),
        latest: createLink(apiPath.latestCars.base),
        detail: {
            base: createLink<IApiCarsDetailParams>(apiPath.carsDetails.base)
        }
    },
    searchMakes: createLink(apiPath.car_search.base),
    carDynamicSearch: createLink<IApiCarsDynamicSearchParams>(apiPath.carDynamicSearch.base),
    favourites: {
        base: createLink(apiPath.favourites.base)
    },
    resetPassword: createLink(apiPath.resetPassword),
    validateToken: createLink<ITokenParams>(apiPath.validateToken),
    userOffers: createLink<IAuthorOffersParams>(apiPath.userOffers.base),
    addCar: createLink<IAddCarParams>(apiPath.addCar.base),
    deleteCar: createLink<IDeleteOffersParams>(apiPath.carData.delete)
};

/**
 * Utils
 */

const appendIncludeParams = <T>(pathname: string, dataQuery: TDataQuery<T>): string => {
    if (isEmpty(dataQuery)) {
        return pathname;
    }

    const includes: string[] = [];
    const addParams = (query: Record<string, any>, parentKey?: string) => {
        const prefix = parentKey ? `${parentKey}.` : "";

        if (parentKey && isEmpty(query)) {
            // set default when keys are not defined
            return includes.push(`i[]=${prefix}*`);
        }

        // keys are defined - append them to array
        each(query, (value: boolean | Record<string, any>, key: string) => {
            const currentKey = prefix + key;
            if (isPlainObject(value)) {
                // we have deep object
                return addParams(value as Record<string, any>, currentKey);
            } else if (value) {
                // we have boolean value - add when truthly
                return includes.push(`i[]=${currentKey}`);
            }
        });
    };

    addParams(dataQuery); // fills `includes` array
    return `${pathname}?${includes.join("&")}`;
};

function createLink<TParams = null>(apiPathElement: string) {
    const compiler = pathToRegexp.compile(apiPathElement);
    return <TQuery>(dataQuery: TDataQuery<TQuery>) => {
        const includeParams = appendIncludeParams("", dataQuery);
        return (apiPathParams: TParams): string => carsApiUrl + compiler(apiPathParams as Object) + includeParams;
    };
}

function createLinkNoParams(apiPathElement: string) {
    return () => carsApiUrl + apiPathElement;
}

import {v4 as uuid} from "uuid";

import {AlertType} from "../constants/AlertType";

export interface IAlertValues {
    type: AlertType;
    text: string;
}

export interface IAlert extends IAlertValues {
    id: string;
    ttl: number;
}

/**
 * Add/remove alert
 */
export const ADD_ALERT = "alerts/ADD_ALERT";
export const REMOVE_ALERT = "alerts/REMOVE_ALERT";

export interface IAddAlertAction {
    type: typeof ADD_ALERT;
    alert: IAlert;
}

export interface IRemoveAlertAction {
    type: typeof REMOVE_ALERT;
    id: string;
}

export const addAlert = (alertValue: IAlertValues, timeToLeave = 1): IAddAlertAction => {
    const id = uuid();
    const alert = {...alertValue, id, ttl: timeToLeave};
    return {type: ADD_ALERT, alert};
};
export const removeAlert = (id: string): IRemoveAlertAction => ({type: REMOVE_ALERT, id});

/**
 * Drop TTL
 */
export const DROP_TTL_AND_REMOVE = "alerts/DROP_TTL_AND_REMOVE";
export const dropAllTtlAndRemoveInvalid = () => ({type: DROP_TTL_AND_REMOVE});

import {Dispatch} from "redux";

import {apiLink} from "../../app/routes/api_link";
import {IServices} from "../../app/services/IServices";
import {createRequestActionTypes} from "../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../app/utils/request_response_utils/request";

export interface ICarsMakesSearch {
    make: string;
    count: number;
}
interface ICarsMakeSearchResponse {
    data: ICarsMakesSearch[];
}

export const fetchAllMakesForSearch = createRequestActionTypes({view: "Home", type: "GET", name: "CarsAllMakesForSearch"});

export const fetchMakesDataForSearch =
    (services: Partial<IServices>) =>
    (dispatch: Dispatch): Promise<ICarsMakeSearchResponse | boolean> => {
        dispatch({type: fetchAllMakesForSearch.start});
        const url = apiLink.searchMakes({})(null);
        return getRequest(services, url, "fetchMakesSearch")
            .then(async (json: ICarsMakeSearchResponse) => {
                dispatch({type: fetchAllMakesForSearch.success, result: {data: json}});

                return json;
            })
            .catch(async (err) => {
                //   console.log("FETCH DETAIL err", err);
                //await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                return false;
            });
    };

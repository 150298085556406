export {globalStyles} from "./src/styles/global_styles";
export {fontFace} from "./src/styles/font_face";
export {CarsThemeProvider} from "./src/styles/CarsThemeProvider";
import {carsTheme as theme} from "@rw/themes";

import "@emotion/react";

export const carsTheme = theme;
export type ICarsTheme = typeof carsTheme;

declare module "@emotion/react" {
    export interface Theme extends ICarsTheme {}
}
